import { Cell, Grid } from '@faceless-ui/css-grid';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { CaseStudy } from '../../../../payload-types';
import { RichText } from '../../RichText';
import { Container } from '../Container';
import { caseStudyHeaderStyles } from './styles';

interface Props {
  caseStudy?: CaseStudy;
}

export const CaseStudyHeader: React.FC<Props> = ({
  caseStudy,
}) => {
  const styles = caseStudyHeaderStyles();

  return (
    <div className={styles.container}>
      <Container>
        <Grid>
          <Cell cols={8}>
            <span className={styles.overTitle}>{caseStudy.overTitle || 'Our Work'}</span>
            <h1 className={styles.title}>{caseStudy.title}</h1>
            <p>{caseStudy.description}</p>
            <a href="#case-study-content">
              Read More
              {' '}
              <FontAwesomeIcon
                icon={faCaretRight}
                className={styles.icon}
              />
            </a>
          </Cell>
          <Cell
            cols={4}
            className={styles.rightColumn}
          >
            <RichText content={caseStudy.headerContentRight} />
          </Cell>
        </Grid>

      </Container>
    </div>
  );
};
