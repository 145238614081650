import { Cell, Grid } from '@faceless-ui/css-grid';
import Image from 'next/image';
import Link from 'next/link';
import React, { useEffect, useRef, useState } from 'react';
import { Service } from '../../../../../payload-types';
import { Button } from '../../../../components';
import { serviceStickyContentStyles } from './styles';

interface Props {
  service: Service;
  midBreak: boolean
}

export const ServiceStickyContent: React.FC<Props> = ({
  service,
  midBreak,
}) => {
  const styles = serviceStickyContentStyles();

  const [visible, setVisible] = useState(false);
  const [centerBodyContent, setCenterBodyContent] = useState(false);
  const ref = useRef(null);
  const bodyContentWrapRef = useRef(null);
  const bodyContentInnerRef = useRef(null);

  useEffect(() => {
    if (!midBreak) {
      const refCopy = ref?.current;
      const codeWrapRefCopy = bodyContentWrapRef?.current;
      let intersectionObserver: IntersectionObserver;
      let resizeObserver: ResizeObserver;

      if (refCopy) {
        intersectionObserver = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              setVisible(entry.isIntersecting);
            });
          },
          {
            rootMargin: '0px 0px 100px 0px',
            threshold: 0.79,
          },
        );

        intersectionObserver.observe(refCopy);
      }

      if (codeWrapRefCopy && bodyContentWrapRef?.current) {
        resizeObserver = new ResizeObserver((entries) => {
          entries.forEach((entry) => {
            setCenterBodyContent(
              entry.contentRect.height > (bodyContentWrapRef?.current?.clientHeight || 0),
            );
          });
        });

        resizeObserver.observe(codeWrapRefCopy);
      }

      return () => {
        if (refCopy) {
          intersectionObserver.unobserve(refCopy);
        }

        if (codeWrapRefCopy) {
          resizeObserver.unobserve(codeWrapRefCopy);
        }
      };
    }

    return () => null;
  }, [ref, midBreak]);


  const content = (
    <Grid className={styles.bodyContainer}>
      <Cell
        cols={6}
        colsM={8}
      >
        <div
          ref={bodyContentWrapRef}
          className={`${styles.bodyContent} ${centerBodyContent ? styles.centerBodyContent : ''} ${visible ? styles.visibleBodyContent : ''}`}
        >
          <div ref={bodyContentInnerRef}>
            <span className={styles.overTitle}>Our Services</span>
            <h2>{service.title}</h2>
            <p>{service.description}</p>
            <Link href={`/service/${service.slug}`}>
              <Button type="link">Find Out More</Button>
            </Link>
          </div>
        </div>

      </Cell>
    </Grid>
  );

  const image = (
    <div className={styles.iamgeContainer}>
      <Grid>
        <Cell
          cols={5}
          start={8}
          colsM={4}
          startM={1}
        >
          {
            typeof service.featuredImage === 'object' ? (
              <Image
                src={service.featuredImage.sizes.card.url || service.featuredImage.url}
                alt={service.featuredImage.alt}
                width={service.featuredImage.sizes.card.width || service.featuredImage.width}
                height={service.featuredImage.sizes.card.height || service.featuredImage.height}
                quality={100}
              />
            ) : null
          }

        </Cell>
      </Grid>
    </div>
  );

  return (
    <div
      className={styles.container}
      ref={ref}
    >
      {image}
      {content}
    </div>
  );
};
