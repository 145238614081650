import { createUseStyles } from 'react-jss';
import {
  base,
  body,
  borderRadius,
  colors,
  headerHeight,
  mobileHeaderHeight,
  transitions,
} from '../../../styles';
import { queries } from '../../../styles/queries';
import zIndex from '../../../styles/zIndex';

export const headerStyles = createUseStyles({
  outerContainer: {
    height: headerHeight,
    position: 'relative',

    [queries.md]: {
      height: mobileHeaderHeight,
    },
  },
  container: {
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.5)',
    position: 'relative',
    zIndex: zIndex.header,

    backgroundColor: colors.white,
  },
  innerContainer: {
    position: 'relative',
    zIndex: zIndex.header,
    height: headerHeight,
    display: 'flex',
    alignItems: 'center',

    [queries.md]: {
      height: mobileHeaderHeight,
    },
  },
  logo: {
    width: base(8),
    [queries.md]: {
      width: base(5),
    },
  },
  menuContainer: {
    marginRight: base(),
    '& > ul': {
      display: 'flex',
      alignItems: 'center',
      listStyle: 'none',
      margin: 0,

      '& li': {
        marginLeft: base(2),
        display: 'flex',
        alignItems: 'center',
        padding: base(0.5),
        '&:hover': {
          background: colors.light,
          borderRadius,
        },

        '&:not(:first-child)': {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        },

        [queries.md]: {
          marginLeft: base(),
          height: mobileHeaderHeight,
          padding: 0,
          '&:hover': {
            background: 'transparent',
            borderRadius,
          },
        },
      },

      '& a': {
        color: colors.dark,
        fontWeight: 600,
        textDecoration: 'none',
      },

      '& span': {
        color: colors.dark,
        fontWeight: 600,
        textDecoration: 'none',
        cursor: 'pointer',
      },
    },

    [queries.sm]: {
      display: 'none',
    },
  },
  mobileMenuButtonContainer: {
    display: 'none',
    marginLeft: 'auto',
    width: base(1.2),
    '& svg': {
      width: base(1.2),
    },

    [queries.sm]: {
      display: 'block !important',
    },

    '& button': {
      background: 'transparent',
      border: 'none',
      padding: 0,
    },
  },
  headerCtaButton: {
    '& button': {
      padding: `${base(0.25)} ${base()}`,
    },
    '@media (max-width: 1070px)': {
      display: 'none',
    },
    marginLeft: 'auto',
  },
  dropdownMenu: {
    zIndex: zIndex.dropdownMenu,
    position: 'relative',
    width: '100%',
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.5)',
    backgroundColor: colors.white,
    opacity: 0,
    visibility: 'hidden',
    transition: transitions.ease,
  },
  dropdownMenuOpen: {
    animation: '$dropdownMenuOpen 0.2s ease-in-out forwards',

    opacity: 1,
    visibility: 'visible',

    [queries.sm]: {
      display: 'none',
    },
  },
  dropdownMenuInnerContainer: {
    padding: base(),

    '& .react-css-grid__grid': {
      [queries.md]: {
        rowGap: 0,
      },
    },
  },
  dropDownMenuOptionsContainer: {
    // height: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',

    '& a': {
      textDecoration: 'none',
    },

  },
  dropDownMenuOption: {
    borderRadius,
    '& > div': {
      padding: base(),

      '& > h3': {
        fontSize: body.fontSize,
        lineHeight: body.lineHeight,
      },
      '& > p': {
        margin: 0,
      },

      [queries.md]: {
        padding: base(0.5),
      },
    },

    cursor: 'pointer',

    '&:hover': {
      backgroundColor: colors.light,
    },
  },
  howWeWorkSection: {
    height: '100%',
    '& > h2': {
      fontSize: base(1.1),
      lineHeight: base(1.65),
    },

    [queries.md]: {
      display: 'none',
    },
  },
  howWeWorkButton: {
    marginTop: base(0.5),
    padding: 0,
    textAlign: 'left',
  },
  '@keyframes dropdownMenuOpen': {
    '0%': {
      transform: 'translateY(-50px)',
    },
    '100%': {
      transform: 'translateY(0)',
    },
  },
  dropDownMenuOptionButton: {
    textDecoration: 'none',
    '& *': {
      textAlign: 'left',
      textDecoration: 'none !important',
    },
  },
});
