import { createUseStyles } from 'react-jss';
import { base, colors, containerSize, queries, spacing } from '../../styles';

export const cardScrollerStyles = createUseStyles({
  container: {
    '& h2': {
      fontSize: base(2.26),
      lineHeight: base(2.26),
    },
    maxWidth: `calc((100vw - ${containerSize.normal}) / 2 + ${containerSize.normal})`,
    width: 'auto',
    marginLeft: 'auto',
    paddingLeft: spacing.normal,

    [queries.md]: {
      paddingLeft: spacing.small,
    },

  },
  sliderTrack: {
    '&::-webkit-scrollbar': {
      height: base(0.5),
      pointerEvents: 'none',
    },
    '&::-webkit-scrollbar-track': {
      background: colors.light,
      pointerEvents: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      pointerEvents: 'none',
    },
    paddingBottom: spacing.small,

    '&:hover': {
      cursor: 'url("/images/cursors/drag-cursor.svg") 31 31, auto',
    },
  },
  slide: {
    display: 'flex',
  },
  card: {
    background: colors.light,
    marginRight: base(),
    padding: base(),
    flex: 1,
  },

});
