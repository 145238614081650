import { createUseStyles } from 'react-jss';
import { base } from '../../styles';

export const ctaStyles = createUseStyles({
  container: {},
  title: {
  },
  buttonsContainer: {
    '& > *:not(:last-child)': {
      marginRight: base(),
    },
  },
});
