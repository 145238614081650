import { createUseStyles } from 'react-jss';
import { base, colors } from '../../styles';

export const articleMetaStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  profilePictureContainer: {
    width: base(2),
    height: base(2),
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    backgroundColor: colors.light,
    '& svg': {
      color: colors.lightGrey,
      width: base(2),
    },
    '& img': {
      width: base(2),
      height: base(2),
    },
    marginRight: base(),
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',

    '& b': {
      display: 'flex',
      alignItems: 'center',
    },

    '& svg': {
      width: base(0.4),
      margin: `0 ${base(0.5)}`,
    },
  },
});
