import { createUseStyles } from 'react-jss';
import { colors, spacing } from '../../../styles';

export const serviceHeaderStyles = createUseStyles({
  container: {
    padding: `${spacing.large} 0`,
    background: colors.dark,

    '& *': {
      color: colors.white,
    },
  },
  title: {
    marginBottom: spacing.small,
    textTransform: 'uppercase',
  },
  overTitle: {
    textTransform: 'uppercase',
  },
  rightColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  buttonContainer: {

  },
});
