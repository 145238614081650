import { createUseStyles } from 'react-jss';
import { base, queries, spacing } from '../../styles';

export const testimonialsStyles = createUseStyles({
  container: {

  },
  sliderTrack: {
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  testimonialItem: {
    textAlign: 'center',
  },
  logoContainer: {
    marginTop: spacing.small,
    display: 'flex',
    justifyContent: 'space-evenly',

    '& img': {
      height: base(3),
      width: 'auto',

      [queries.md]: {
        height: base(2),
        marginRight: base(1),
      },
    },

    '& button': {
      background: 'none',
      border: 'none',
      cursor: 'pointer',

      opacity: 0.5,
      filter: 'grayscale(100%)',

      transition: 'all 0.3s ease-in-out',

      '&:hover': {
        opacity: 1,
        filter: 'grayscale(0%)',
      },
    },
  },
  logoActive: {
    opacity: '1 !important',
    filter: 'grayscale(0%) !important',
  },
  sliderButton: {

  },
});
