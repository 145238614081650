import React from 'react';
import { BackgroundColorType } from '../../../utilities/type';
import { backgroundColorStyles } from './styles';

interface Props {
  backgroundColor?: BackgroundColorType;
  children: React.ReactNode;
}

export const BackgroundColor: React.FC<Props> = ({
  backgroundColor,
  children,
}) => {
  const styles = backgroundColorStyles();

  if (backgroundColor === 'light') {
    return (
      <div className={styles.lightContainer}>
        {children}
      </div>
    );
  }

  if (backgroundColor !== 'dark') {
    return (
      <React.Fragment>
        {children}
      </React.Fragment>
    );
  }

  return (
    <div className={styles.container}>
      {children}
    </div>
  );
};
