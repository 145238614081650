import React, { useEffect, useState } from 'react';
import useIntersect from '../../../hooks/useIntersect';
import { slideInContainerStyles } from './styles';

interface Props {
  children: React.ReactNode;
  animateDelay?: number;
  direction: 'left' | 'right' | 'up' | 'down';
}

export const SlideInContainer: React.FC<Props> = ({
  children,
  animateDelay = 0,
  direction,
}) => {
  const [loaded, setLoaded] = useState(false);
  const [setNote, entry] = useIntersect({
    threshold: 0.5,
  });

  const styles = slideInContainerStyles({
    direction,
  });

  useEffect(() => {
    if (entry?.isIntersecting) {
      setTimeout(() => {
        setLoaded(true);
      }, animateDelay);
    }
  }, [animateDelay, entry?.isIntersecting]);

  return (
    <div
      className={styles.container}
      ref={setNote}
    >
      <div className={`${styles.slidingContainer} ${loaded ? styles.slidingContainerActive : ''}`}>
        {children}
      </div>
    </div>
  );
};
