import { Cell, Grid } from '@faceless-ui/css-grid';
import { useWindowInfo } from '@faceless-ui/window-info';
import Image from 'next/image';
import React from 'react';
import { Media } from '../../../payload-types';
import { BackgroundColor, Container, RichText, Spacing } from '../../components';
import { breakpoints } from '../../styles';
import { BackgroundColorType, SpacingType } from '../../utilities/type';
import { imageContentStyles } from './styles';

interface Props {
  imageContent: {
    backgroundColor?: BackgroundColorType;
    spacings: SpacingType;
    imagePosition?: 'left' | 'right';
    imageWidth: 'half' | 'third';
    image: string | Media;
    content?: {
      [k: string]: unknown;
    }[];
  };
  id?: string;
  blockName?: string;
  blockType: 'imageContent';
}

export const ImageContent: React.FC<Props> = ({
  imageContent,
}) => {
  const styles = imageContentStyles();
  const windowInfo = useWindowInfo();
  const isTablet = windowInfo.width <= breakpoints.md;

  return (
    <BackgroundColor backgroundColor={imageContent.backgroundColor}>
      <Spacing spacing={imageContent.spacings}>
        <Container className={styles.container}>
          <Grid>
            {
              !isTablet && typeof imageContent.image === 'object' && imageContent.imagePosition === 'left' ? (
                <Cell
                  cols={imageContent.imageWidth === 'half' ? 6 : 4}
                >
                  <div className={styles.imageContainer}>
                    <Image
                      src={imageContent.image.sizes.card.url || imageContent.image.url}
                      alt={imageContent.image.alt}
                      width={imageContent.image.sizes.card.width || imageContent.image.width}
                      height={imageContent.image.sizes.card.height || imageContent.image.height}
                      quality={100}
                    />
                  </div>
                </Cell>
              ) : null
            }
            <Cell
              cols={imageContent.imageWidth === 'half' ? 6 : 8}
              className={styles.contentContainer}
            >
              <RichText content={imageContent.content} />
            </Cell>
            {
              typeof imageContent.image === 'object' && (imageContent.imagePosition === 'right' || isTablet) ? (
                <Cell
                  cols={imageContent.imageWidth === 'half' ? 6 : 4}
                >
                  <div className={styles.imageContainer}>
                    <Image
                      src={imageContent.image.sizes.card.url || imageContent.image.url}
                      alt={imageContent.image.alt}
                      width={imageContent.image.sizes.card.width || imageContent.image.width}
                      height={imageContent.image.sizes.card.height || imageContent.image.height}
                      quality={100}
                    />
                  </div>
                </Cell>
              ) : null
            }
          </Grid>
        </Container>
      </Spacing>
    </BackgroundColor>
  );
};
