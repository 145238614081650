import { createUseStyles } from 'react-jss';
import { base, colors, queries, spacing } from '../../styles';

export const errorStyles = createUseStyles({
  container: {
    height: 'calc(70vh)',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    backgroundColor: colors.dark,

    marginBottom: `calc(${base(-2)} + 2px)`,

    color: colors.white,

    padding: base(),
  },
  title: {
    fontWeight: 'bold',
    color: 'transparent',
    '-webkit-text-stroke': '1px white',

    fontSize: base(7),
    lineHeight: base(7),

    marginBottom: spacing.small,

    [queries.sm]: {
      fontSize: base(4),
      lineHeight: base(4),
    },
  },
  text: {
    color: colors.white,
    marginBottom: spacing.normal,
    maxWidth: '500px',
    textAlign: 'center',
  },
  button: {
    background: colors.white,
  },
});
