import { createUseStyles } from 'react-jss';
import { base, h3 } from '../../../../styles';

export const iconStyles = createUseStyles({
  icon: {
    width: '100%',
    display: 'inline-flex',
    alignItems: 'flex-start',
    '& > span': {
      maxWidth: h3.fontSize,
      maxHeight: h3.fontSize,
      width: '100%',
      height: '100%',
      marginRight: base(0.5),
      '& svg': {
        maxWidth: base(1.4),
        maxHeight: base(1.4),
        width: '100%',
        height: '100%',
      },
    },
  },
  'icon-small': {
    '& > span': {
      maxWidth: base(),
      maxHeight: base(),
      width: '100%',
      height: '100%',
      '& svg': {
        maxWidth: base(),
        maxHeight: base(),
        width: '100%',
        height: '100%',
      },
    },
  },
  'icon-large': {
    '& > span': {
      maxWidth: h3.fontSize,
      maxHeight: h3.fontSize,
      width: '100%',
      height: '100%',
      '& svg': {
        maxWidth: h3.fontSize,
        maxHeight: h3.fontSize,
        width: '100%',
        height: '100%',
      },

    },
    '& *': {
      fontSize: h3.fontSize,
      fontWeight: h3.fontWeight,
    },
  },
});
