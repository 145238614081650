import { Cell, Grid } from '@faceless-ui/css-grid';
import { useWindowInfo } from '@faceless-ui/window-info';
import React, { useEffect, useState } from 'react';
import { BackgroundColor, Container, RichText, SlideInContainer, Spacing } from '../../components';
import { breakpoints } from '../../styles';
import { BackgroundColorType, SpacingType } from '../../utilities/type';
import { contentStyles } from './styles';

interface Props {
  content: {
    backgroundColor?: BackgroundColorType;
    spacings: SpacingType;
    reverseOrderMobile: boolean;
    columns: {
      width: 'full' | 'twoThird' | 'half' | 'third';
      alignment: 'left' | 'center' | 'right';
      verticalAlignment: 'top' | 'center' | 'bottom';
      content?: {
        [k: string]: unknown;
      }[];
      id?: string;
    }[];
  }
}

const colStyles = {
  third: {
    cols: 4,
  },
  half: {
    cols: 6,
  },
  twoThird: {
    cols: 8,
  },
  full: {
    cols: 12,
  },
};

export const Content: React.FC<Props> = ({ content }) => {
  const styles = contentStyles();

  // flip the columns if the window is mobile
  const { width } = useWindowInfo();
  const [columns, setColumns] = useState(content.columns);

  useEffect(() => {
    if (width < breakpoints.md && content.reverseOrderMobile) {
      setColumns([...content.columns].reverse());
    } else {
      setColumns(content.columns);
    }
  }, [width, content.reverseOrderMobile, content.columns]);

  const isThreeThirdColumns = columns.every((column) => column.width === 'third');

  return (
    <BackgroundColor backgroundColor={content.backgroundColor}>
      <Spacing spacing={content.spacings}>
        <Container className={styles.container}>
          <Grid>
            {
             columns.map((column, index) => (
               <Cell
                 key={column.id}
                 className={`${styles[`align-${column.alignment}`]} ${styles[`verticalAlign-${column.verticalAlignment}`]}`}
                 {...colStyles[column.width]}
                 colsM={8}
               >
                 {
                  isThreeThirdColumns ? (
                    <SlideInContainer
                      direction="up"
                      animateDelay={300 * index}
                    >
                      <RichText content={column.content} />
                    </SlideInContainer>
                  ) : (
                    <RichText content={column.content} />
                  )
                }
               </Cell>
             ))
            }
          </Grid>
        </Container>
      </Spacing>
    </BackgroundColor>
  );
};
