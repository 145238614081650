import React from 'react';
import { SpacingType } from '../../../utilities/type';
import { spacingStyles } from './styles';

interface Props {
  children: React.ReactNode;
  spacing?: SpacingType
}

export const Spacing: React.FC<Props> = ({
  children,
  spacing,
}) => {
  const styles = spacingStyles({
    spacing,
  });

  return (
    <div className={styles.container}>
      {children}
    </div>
  );
};
