import { createUseStyles } from 'react-jss';
import { containerSize, spacing } from '../../../styles';
import { queries } from '../../../styles/queries';

interface Props {
  containerSize?: 'large' | 'normal' |'small';
}

export const containerStyles = createUseStyles({
  container: {
    maxWidth: ({ containerSize: size }: Props) => containerSize[size],
    width: 'auto',
    margin: '0 auto',
    padding: `0 ${spacing.normal}`,

    [queries.md]: {
      padding: `0 ${spacing.small}`,
    },
  },
});
