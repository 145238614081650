import { createUseStyles } from 'react-jss';
import { base, colors, queries, spacing } from '../../../../styles';

interface Props {
  scrollDirection: 'up' | 'down';
}

export const fadeScrollSectionsContentStyles = createUseStyles({
  itemContainer: {

    height: '80vh',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    opacity: 0,
  },
  cellContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    marginLeft: base(-4),

    [queries.md]: {
      marginLeft: base(),
    },
  },
  imageCellContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  element: {
    opacity: 0,
  },
  elementCenter: (props: Props) => ({
    animation: `$fadeFrom${props.scrollDirection === 'up' ? 'Top' : 'Bottom'} 0.3s ease-in-out`,
    position: 'fixed',
    top: '50%',
    transform: 'translateY(-50%)',
    opacity: 1,
    transition: 'opacity 0.3s ease-in-out',
  }),
  buttonContainer: {
    marginTop: spacing.medium,
  },
  image: {
    width: '100%',
    height: 'auto',
    [queries.md]: {
      display: 'none',
    },
  },
  fadeOut: {
    animation: '$fadeOut 0.2s ease-in-out',
  },
  '@keyframes fadeOut': {
    '0%': {
      opacity: 1,
    },
    '100%': {
      opacity: 0,
    },
  },
  fadeIn: {
    animation: '$fadeIn 0.3s ease-in-out',
    opacity: 1,
  },
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
    },
    '50%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
  '@keyframes fadeFromBottom': {
    '0%': {
      position: 'fixed',
      top: '100%',
      opacity: 0,
    },
    '100%': {
      position: 'fixed',
      top: '50%',
      transform: 'translateY(-50%)',
      opacity: 1,
    },
  },
  '@keyframes fadeFromTop': {
    '0%': {
      position: 'fixed',
      top: '0%',
      opacity: 0,
    },
    '100%': {
      position: 'fixed',
      top: '50%',
      transform: 'translateY(-50%)',
      opacity: 1,
    },
  },
  navScrollerContainer: {
    display: 'flex',
    flexDirection: 'column',

    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',

    '& span': {
      display: 'block',
      marginBottom: spacing.small,
      borderLeft: `3px solid ${colors.white}`,
      opacity: 0.5,
    },
  },
  activeNavScrollerItem: {
    opacity: '1 !important',
  },
});
