import Link from 'next/link';
import React from 'react';
import { Button } from '../Button';
import { paginationStyles } from './styles';

interface Props {
  hasNextPage: boolean;
  hasPrevPage: boolean;
  currentPage: number;
  href: string;
}

export const Pagination: React.FC<Props> = ({
  hasNextPage,
  hasPrevPage,
  currentPage,
  href,
}) => {
  const styles = paginationStyles();

  return (
    <div className={styles.container}>
      {hasPrevPage && (
        <Link href={`${href}?page=${currentPage - 1}`}>
          <Button>
            Previous
          </Button>
        </Link>
      )}
      {hasNextPage && (
        <Link href={`${href}?page=${currentPage + 1}`}>
          <Button>
            Next
          </Button>
        </Link>
      )}
    </div>
  );
};
