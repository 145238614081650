import React from 'react';
import { Page } from '../../../../payload-types';
import { DefaultPageHeader } from '../DefaultPageHeader';
import { HomeHeader } from '../HomeHeader';

interface Props {
  page: Page
}

export const PageHeader: React.FC<Props> = ({
  page,
}) => {
  if (page.header.type === 'featuredImage') {
    return (
      <div>
        <h1>Default Header</h1>
      </div>
    );
  }

  if (page.header.type === 'homeHeader') {
    return (
      <HomeHeader page={page} />
    );
  }

  return <DefaultPageHeader page={page} />;
};
