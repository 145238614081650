import React from 'react';
import * as components from '../../blocks';
import { Article, CaseStudy, Page } from '../../../payload-types';

type Props = {
  layout: Page['layout'] | Article['content'] | CaseStudy['layout'];
  className?: string;
};

export const RenderBlocks: React.FC<Props> = ({ layout, className }) => (
  <div className={className}>
    {layout.map((block, i) => {
      if (!block || !block.blockType) return null;
      // make blockType first character uppercase
      const blockType = `${
        block.blockType.charAt(0).toUpperCase() + block.blockType.slice(1)
      }`;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const Block: React.FC<any> = components[blockType];

      if (Block) {
        return (
          <section
            key={i}
            id={block.blockName}
          >
            <Block {...block} />
          </section>
        );
      }

      return null;
    })}
  </div>
);
