import React from 'react';
import { iconStyles } from './styles';

interface Props {
  className?: string;
}

export const Logo: React.FC<Props> = ({ className }) => {
  const styles = iconStyles();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 59.2"
      className={`${styles.logo} ${className}`}
    >
      <path d="M51.2 35.3c-.8-1.51-1.62-2.95-2.46-4.34.02.39.03.77.03 1.16.03 12-9.7 21.79-21.7 21.82-12 .03-21.79-9.7-21.82-21.7-.03-12 9.7-21.79 21.7-21.82 4.86-.01 9.36 1.57 12.99 4.27l7.63-.02c-.48-.56-.99-1.11-1.52-1.64a26.966 26.966 0 0 0-8.6-5.76c-3.34-1.4-6.88-2.1-10.52-2.09s-7.18.73-10.51 2.15c-3.21 1.37-6.1 3.33-8.57 5.81a26.966 26.966 0 0 0-5.76 8.6C.69 25.08-.01 28.62 0 32.26s.73 7.18 2.15 10.51c1.37 3.21 3.33 6.1 5.81 8.57 2.49 2.47 5.38 4.41 8.6 5.76 3.34 1.4 6.88 2.1 10.52 2.09 3.64-.01 7.18-.73 10.51-2.15 3.21-1.37 6.1-3.33 8.57-5.81 2.47-2.49 4.41-5.38 5.76-8.6a26.8 26.8 0 0 0 1.19-3.47c-.59-1.28-1.23-2.56-1.91-3.86Z" />
      <path d="m100 12.47-3.4 4.27a203.08 203.08 0 0 0-16.88 24.82c-3.26 5.66-6.28 11.54-8.97 17.5l-5.69.02c-2.05-6.09-4.52-12.08-7.35-17.83-3.97-8.09-8.71-15.87-14.1-23.12l.12-.09 6.28-.02c4.68 6.63 8.84 13.65 12.41 20.92 2.11 4.31 4.03 8.75 5.74 13.26a206.976 206.976 0 0 1 21.02-34.44l-23.29.07-.67-1.63a45.786 45.786 0 0 0-7.87-12.74L61.31.01a51.426 51.426 0 0 1 8.06 12.56l30.62-.09Z" />
    </svg>
  );
};
