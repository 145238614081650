import { Cell, Grid } from '@faceless-ui/css-grid';
import React from 'react';
import { Service } from '../../../../payload-types';
import { AppLink } from '../../AppLink';
import { Button } from '../../Button';
import { Container } from '../Container';
import { serviceHeaderStyles } from './styles';

interface Props {
  service?: Service;
}

export const ServiceHeader: React.FC<Props> = ({
  service,
}) => {
  const styles = serviceHeaderStyles();

  return (
    <div className={styles.container}>
      <Container>
        <Grid>
          <Cell cols={8}>
            <span className={styles.overTitle}>{service.overTitle}</span>
            <h1 className={styles.title}>{service.title}</h1>
            <p>{service.description}</p>
            <div className={styles.buttonContainer}>
              {
                service.buttons.map(({ button, id }) => (
                  <Button
                    type={button.type}
                    key={id}
                    dark
                  >
                    <AppLink link={button.link} />
                  </Button>
                ))
              }
            </div>
          </Cell>
          <Cell
            cols={4}
            className={styles.rightColumn}
          >
            <span />
          </Cell>
        </Grid>

      </Container>
    </div>
  );
};
