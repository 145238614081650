import { Footer, Header, SocialMedia } from '../../../../payload-types';
import { LINK_FIELD, MEDIA_FIELD } from '../fields';

const GLOBALS = `
  query Globals {
    Header {
      mainMenu {
        id
        type
        link {
          ${LINK_FIELD}
        }
        dropdown {
          title
          links {
            id
            description
            link {
              ${LINK_FIELD}
            }
          }
          featuredCaseStudy {
            enableCaseStudy
            caseStudy {
              id
              slug
              title
              featuredImage {
                ${MEDIA_FIELD}
              }
              createdAt
              updatedAt
            }
          }
        }
      }
      ctaButton {
        button {
          type
          link {
            ${LINK_FIELD}
          }
        }
      }
      topNoticeBar {
        isActive
        leftText
        rightText
        link {
          ${LINK_FIELD}
        }
      }
    }
    Footer {
      links {
        id
        link {
          ${LINK_FIELD}
        }
      }
      menuLists {
        id
        title
        links {
          id
          link {
            ${LINK_FIELD}
          }
        }
      }
      bottomBar {
        links {
          id
          link {
            ${LINK_FIELD}
          }
        }
      }
    }
    SocialMedia {
      socialMedia {
        id
        icon
        link {
          ${LINK_FIELD}
        }
      }
    }
  }
`;

export const fetchGlobals = async (): Promise<{
  header: Header;
  footer: Footer
  socialMedia: SocialMedia
}> => {
  const { data, errors } = await fetch(
    `${process.env.NEXT_PUBLIC_SERVER_URL}/api/graphql`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      next: {
        revalidate: 600,
      },
      body: JSON.stringify({
        query: GLOBALS,
      }),
    },
  ).then((res) => res.json());

  if (errors) {
    console.error(JSON.stringify(errors));
    throw new Error();
  }

  return {
    header: data.Header,
    footer: data.Footer,
    socialMedia: data.SocialMedia,
  };
};
