import React from 'react';
import { Header } from '../../../payload-types';
import { AppLink } from '../AppLink';
import { Container } from '../layouts';
import { headerNoticeStyles } from './styles';

interface Props {
  data: Header['topNoticeBar']
}

export const HeaderNotice: React.FC<Props> = ({
  data,
}) => {
  const styles = headerNoticeStyles();

  if (!data.isActive) return null;

  return (
    <AppLink
      link={data.link}
      className={styles.linkContainer}
    >
      <div className={styles.container}>
        <Container
          containerSize="large"
          className={styles.innerContainer}
        >
          <p>{data.leftText}</p>
          <p>{data.rightText}</p>
        </Container>
      </div>
    </AppLink>
  );
};
