import { Cell, Grid } from '@faceless-ui/css-grid';
import React, { useEffect } from 'react';
import { SliderProvider, SliderTrack, Slide } from '@faceless-ui/slider';
import { useWindowInfo } from '@faceless-ui/window-info';
import { useRouter } from 'next/router';
import { BackgroundColor, RichText, Spacing } from '../../components';
import { BackgroundColorType, SpacingType } from '../../utilities/type';
import { cardScrollerStyles } from './styles';
import { breakpoints } from '../../styles';

interface Props {
  cardScroller: {
    backgroundColor?: BackgroundColorType;
    spacings: SpacingType;
    hasLeftContent?: boolean;
    leftContent?: {
      [k: string]: unknown;
    }[];
    cards: {
      content?: {
        [k: string]: unknown;
      }[];
      id?: string;
    }[];
  };
  id?: string;
  blockName?: string;
  blockType: 'cardScroller';
}

export const CardScroller: React.FC<Props> = ({
  cardScroller,
}) => {
  const styles = cardScrollerStyles();
  const windowInfo = useWindowInfo();
  const router = useRouter();

  useEffect(() => {
    // reset state
  }, [router.asPath]);

  // eslint-disable-next-line no-nested-ternary
  const slidesToShow = windowInfo.width > breakpoints.lg ? 2
    // eslint-disable-next-line no-nested-ternary
    : windowInfo.width > breakpoints.md ? 2
      // eslint-disable-next-line no-nested-ternary
      : windowInfo.width > 850 ? 3
        : windowInfo.width > breakpoints.sm ? 2
          : 1;

  return (
    <BackgroundColor backgroundColor={cardScroller.backgroundColor}>
      <Spacing spacing={cardScroller.spacings}>
        <div className={styles.container}>
          <Grid>
            {
              cardScroller.hasLeftContent && (
                <Cell cols={4}>
                  <RichText content={cardScroller.leftContent} />
                </Cell>
              )
            }
            <Cell cols={cardScroller.hasLeftContent ? 8 : 12}>
              <SliderProvider
                slidesToShow={slidesToShow}
                slideOnSelect
              >
                <SliderTrack className={styles.sliderTrack}>
                  {
                    cardScroller.cards.map((card, index) => (
                      <Slide
                        key={card.id}
                        index={index}
                        className={styles.slide}
                      >
                        <div
                          className={styles.card}
                        >
                          <RichText content={card.content} />
                        </div>
                      </Slide>
                    ))
                  }
                </SliderTrack>
              </SliderProvider>
            </Cell>
          </Grid>
        </div>
      </Spacing>
    </BackgroundColor>
  );
};
