import { createUseStyles } from 'react-jss';
import { base, body, colors, queries, spacing } from '../../styles';

export const headerCaseStudyCardStyles = createUseStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: spacing.small,
    background: 'transparent',
    border: 'none',
    textAlign: 'start',
    cursor: 'pointer',
    [queries.xl]: {
      flexDirection: 'column',
    },
    [queries.md]: {
      display: 'none',
    },
  },
  title: {
    fontSize: body.fontSize,
    lineHeight: body.lineHeight,
  },
  linkContainer: {
    display: 'flex',
    textDecoration: 'none',
    color: 'inherit',
    padding: spacing.small,
    borderRadius: base(0.25),
    '&:hover': {
      textDecoration: 'none',
      background: colors.light,
    },
  },
  contentContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: spacing.xSmall,
  },
  description: {
    margin: 0,
  },
  imageContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
  },
  image: {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
    borderRadius: base(0.25),
  },
  link: {
    color: 'inherit',
    textDecoration: 'underline',
  },
});
