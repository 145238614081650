import { createUseStyles } from 'react-jss';
import { base, colors } from '../../styles';

export const breadcrumbStyles = createUseStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  link: {
    '& a': {
      color: colors.dark,
    },
    '&:not(:last-child):after': {
      content: '"/"',
      margin: `0 ${base(0.4)}`,
    },
    '&:not(:last-child)': {
      '& a': {
        color: colors.lightGrey,

        '&:hover': {
          color: colors.dark,
        },
      },
    },
  },
});
