// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export const calculateContentReadTime = (content: any): number => {
  const text = JSON.stringify(content).replace(/(<([^>]+)>)/gi, '');
  const wordsPerMinute = 200;
  const noOfWords = text.split(/\s/g).length;
  const minutes = noOfWords / wordsPerMinute;
  const readTime = Math.ceil(minutes);
  return readTime;
};

export const truncateString = (str: string, num?: number): string => {
  if (str) {
    // eslint-disable-next-line no-constant-condition
    return str.length > num || 200 ? `${str.slice(0, num || 200)}...` : str;
  }
  return '';
};

export const formatDate = (date: string): string => {
  const d = new Date(date);
  const month = d.toLocaleString('default', { month: 'short' });
  const day = d.getDate();
  const year = d.getFullYear();
  return `${month} ${day}, ${year}`;
};

export const constructClassName = (classNames: (string | boolean | undefined)[]): string => classNames.filter(Boolean).join(' ');
