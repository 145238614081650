import { createUseStyles } from 'react-jss';

export const imageContentStyles = createUseStyles({
  container: {

  },
  imageContainer: {
    '& img': {
      width: '100%',
      height: 'auto',
    },
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
});
