import React from 'react';
import { Grid, Cell } from '@faceless-ui/css-grid';
import Image from 'next/image';
import { useWindowInfo } from '@faceless-ui/window-info';
import Link from 'next/link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as icons from '@fortawesome/free-solid-svg-icons';
import { Container } from '../Container';
import { HomeHeaderStyles } from './styles';
import { Button } from '../../Button';
import { Page } from '../../../../payload-types';
import { AppLink } from '../../AppLink';
import { breakpoints } from '../../../styles';
import { Tag } from '../../Tag';

interface Props {
  page: Page
}

export const HomeHeader: React.FC<Props> = ({
  page,
}) => {
  const styles = HomeHeaderStyles();
  const windowInfo = useWindowInfo();
  const isTablet = windowInfo.width < breakpoints.md;

  const animation = (
    <Cell
      cols={6}
      className={styles.headerContainer}
    >
      <div className={styles.rightContainer}>
        <video
          autoPlay
          loop
          muted
          playsInline
          className={styles.homeVideo}
        >
          <source src="/media/home-header-animation.mp4" />
        </video>
        <div className={styles.topLeftTags}>
          <Tag icon="faWandMagicSparkles">
            Innovative
          </Tag>
        </div>
        <div className={styles.bottomRightTags}>
          <Tag aniamteDelay={500}>
            E-Commerce
          </Tag>
          <Tag aniamteDelay={1000}>
            Corporate
          </Tag>
          <Tag aniamteDelay={1500}>
            SME&apos;s
          </Tag>
        </div>
      </div>
    </Cell>
  );

  return (
    <div className={styles.container}>
      <Container
        className={styles.gridContainer}
        containerSize="large"
      >
        <Grid style={{ height: '100%' }}>
          {isTablet && animation}
          <Cell
            cols={6}
            className={styles.headerContainer}
          >
            <h1 className={styles.title}>{page.title}</h1>
            <p className={styles.description}>{page.description}</p>
            <div className={styles.buttonContainer}>
              {
                page.header.buttons.map(({ button, id }) => (
                  <AppLink
                    link={button.link}
                    key={id}
                  >
                    <Button
                      dark
                      className={styles.primaryButton}
                      type={button.type}
                      icon={

                        <FontAwesomeIcon icon={icons[button.icon]} />
                      }
                    >
                      {button.link.label}
                    </Button>
                  </AppLink>
                ))
              }
            </div>
            <Link href="#testimonials">
              <div className={styles.trustedByOuterContianer}>
                <div className={`${styles.trustedByContainer} invx-trustedby__container`}>
                  {
                    page.header.trustedByLogos.map(({ logo, id }) => (typeof logo === 'object' ? (
                      <Image
                        key={id}
                        src={logo.sizes.square.url || logo.url}
                        alt={logo.alt}
                        width={logo.sizes.square.width || logo.width}
                        height={logo.sizes.square.height || logo.height}
                        quality={100}
                      />
                    ) : null))
                  }
                </div>
                <Button
                  type="secondary"
                  dark
                >
                  Read Client Testimonials
                </Button>
              </div>
            </Link>
          </Cell>
          {!isTablet && animation}
        </Grid>
      </Container>
    </div>
  );
};
