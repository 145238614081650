import React, { useEffect } from 'react';
import { Slide, SliderButton, SliderProvider, SliderTrack } from '@faceless-ui/slider';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { testimonialsStyles } from './styles';
import { Media } from '../../../payload-types';
import { BackgroundColor, Container, RichText, Spacing } from '../../components';

interface Props {
  testimonials: {
    backgroundColor?: 'none' | 'dark' | 'light';
    spacings: {
      top?: 'none' | 'small' | 'medium' | 'large';
      bottom?: 'none' | 'small' | 'medium' | 'large';
    };
    testimonials: {
      logo: string | Media;
      content?: {
        [k: string]: unknown;
      }[];
      id?: string;
    }[];
  };
  id?: string;
  blockName?: string;
  blockType: 'testimonials';
}

export const Testimonials: React.FC<Props> = ({
  testimonials,
}) => {
  const styles = testimonialsStyles();
  const router = useRouter();

  useEffect(() => {
    // reset state
  }, [router.asPath]);

  return (
    <BackgroundColor backgroundColor={testimonials.backgroundColor}>
      <Spacing spacing={testimonials.spacings}>
        <SliderProvider
          slidesToShow={1}
          autoPlay
          autoplaySpeed={5000}
        >
          {
            ({ currentSlideIndex }) => (
              <React.Fragment>
                <SliderTrack className={styles.sliderTrack}>
                  {
                testimonials.testimonials.map((testimonial, index) => (
                  <Slide
                    key={index}
                    index={index}
                  >
                    <Container
                      containerSize="small"
                      className={styles.testimonialItem}
                    >
                      <RichText content={testimonial.content} />
                    </Container>
                  </Slide>
                ))
              }
                </SliderTrack>
                <Container
                  containerSize="small"
                  className={styles.logoContainer}
                >
                  {
                    testimonials.testimonials.map(({ logo }, index) => typeof logo === 'object' && (
                      <SliderButton
                        key={index}
                        index={index}
                        className={`${styles.sliderButton} ${currentSlideIndex === index ? styles.logoActive : ''}`}
                      >
                        <Image
                          src={logo.sizes.card.url || logo.url}
                          alt={logo.alt}
                          width={logo.sizes.card.width || logo.width}
                          height={logo.sizes.card.height || logo.height}
                          quality={100}
                        />
                      </SliderButton>

                    ))
                  }
                </Container>
              </React.Fragment>
            )
          }
        </SliderProvider>
      </Spacing>
    </BackgroundColor>
  );
};
