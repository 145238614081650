export const LINK_FIELD = `
  type
  url
  reference {
    value {
      ... on Page {
        slug
      }
      ... on Article {
        slug
      }
      ... on Service {
        slug
      }
      ... on CaseStudy {
        slug
      }
    }
    relationTo
  }
  label
  noreferer
  noFollow
  newTab
`;
