import { createUseStyles } from 'react-jss';
import { base, body, colors, spacing } from '../../../styles';

export const subscribeNewsletterFormStyles = createUseStyles({
  container: {},
  error: {
    color: colors.red,
  },
  success: {
    color: colors.aqua,
  },
  inputContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: `${base(0.5)} ${base(0.5)} ${base(0.5)} ${base()}`,
    backgroundColor: colors.grey,

    '& input': {
      width: '100%',
      backgroundColor: 'transparent',
      border: 0,
      ...body,
      color: colors.white,
      '&:focus': {
        outline: 'none',
      },

      '&::placeholder': {
        ...body,
        color: colors.white,
      },
    },

    '& button': {
      padding: `${base(0.5)} ${base(2)}`,
    },

    marginBottom: spacing.small,
  },
  submitButton: {
    backgroundColor: `${colors.dark} !important`,
    color: `${colors.white} !important`,
    '& *': {
      color: `${colors.white} !important`,
      width: '100%',
    },
  },
});
