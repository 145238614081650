/* eslint-disable no-nested-ternary */
import { createUseStyles } from 'react-jss';
import { base, body, colors } from '../../styles';

interface Props {
  dark: boolean;
  disableAnimation: boolean;
}

export const buttonStyles = createUseStyles({
  button: {
    position: 'relative',
    padding: `${base(0.5)} ${base(2)}`,
    '& > span': {
      fontWeight: 600,
      display: 'flex',
    },
    border: 0,
    cursor: 'pointer',

    outline: 'none !important',

    overflow: 'hidden',
  },
  primary: ({ dark, disableAnimation }: Props) => ({
    backgroundColor: dark ? colors.white : colors.dark,

    '& span, & span *': {
      color: dark ? `${colors.dark} !important` : colors.white,
      transition: 'all 0.3s linear 0.6s',
    },

    '& *': {
      textDecoration: 'none !important',
    },

    '&:hover': {
      backgroundColor: disableAnimation ? colors.dark : dark ? colors.dark : colors.white,
      '& *': {
        color: disableAnimation ? colors.white : dark ? `${colors.white} !important` : colors.dark,
      },

      '& span, & span *': {
        color: disableAnimation ? colors.white : dark ? `${colors.white} !important` : colors.dark,
        transition: 'all 0.3s linear',
      },

      transition: 'all 0.3s linear',

      '& .invx-button__top-border': {
        transition: 'all .2s linear',
        transform: 'scaleX(1)',
        opacity: '1',
      },
      '& .invx-button__left-border, & .invx-button__right-border': {
        transition: 'all .1s linear .2s',
        transform: 'translateY(0)',
      },
      '& .invx-button__bottom-left-border, & .invx-button__bottom-right-border': {
        transition: 'all .2s linear .3s',
        transform: 'translateX(0)',
      },
    },
    transition: 'all 0.3s linear 0.6s',
  }),
  secondary: ({ dark }: Props) => ({
    backgroundColor: 'transparent',
    '& *': {
      color: dark ? `${colors.white} !important` : colors.dark,
    },
    '& .button__loading-icon': {
      '& circle': {
        stroke: colors.dark,
      },
    },

    '&:hover': {
      '& .button__icon': {
        opacity: 1,
        transform: 'translateX(0)',
      },
    },
  }),
  buttonIcon: {
    marginLeft: base(),
    color: 'inherit',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    opacity: 0,
    transform: 'translateX(-100%)',
    transition: 'all 0.3s linear 0.2s',
  },
  loadingIcon: ({ dark }: Props) => ({
    height: base(),
    width: base(),
    marginLeft: base(),
    color: 'inherit',
    '& circle': {
      stroke: dark ? colors.dark : colors.white,
    },
  }),
  linkButton: {
    ...body,
    border: 0,
    padding: 0,
    backgroundColor: 'transparent',
    cursor: 'pointer',
  },
  topBorder: ({ dark }: Props) => ({
    left: 0,
    background: dark ? `${colors.white} !important` : colors.dark,
    position: 'absolute',
    top: '0px',
    width: '100%',
    height: '2px',
    transform: 'scaleX(0)',

    margin: 'auto',
    opacity: '1',
    transition: 'all .2s linear .3s',
  }),
  rightBorder: ({ dark }: Props) => ({
    background: dark ? `${colors.white} !important` : colors.dark,
    position: 'absolute',
    top: '0',
    right: '0',
    width: '2px',
    height: '100%',
    transform: 'translateY(-100%)',
    margin: 'auto',
    transition: 'all .1s linear .2s',
  }),
  leftBorder: ({ dark }: Props) => ({
    background: dark ? `${colors.white} !important` : colors.dark,
    position: 'absolute',
    top: '0',
    left: '0',
    width: '2px',
    height: '100%',
    transform: 'translateY(-100%)',
    margin: 'auto',
    transition: 'all .1s linear .2s',
  }),
  bottomLeftBorder: ({ dark }: Props) => ({
    background: dark ? `${colors.white} !important` : colors.dark,
    position: 'absolute',
    bottom: '0',
    left: '0',
    width: '50%',
    transform: 'translateX(-101%)',
    height: '2px',
    margin: 'auto',
    transition: 'all .2s linear',
  }),
  bottomRightBorder: ({ dark }: Props) => ({
    background: dark ? `${colors.white} !important` : colors.dark,
    position: 'absolute',
    bottom: '0',
    right: '0',
    width: '50%',
    transform: 'translateX(101%)',
    height: '2px',
    margin: 'auto',
    transition: 'all .2s linear',
  }),
  buttonInnerSpan: {
    display: 'flex',
    alignItems: 'center',
  },
});
