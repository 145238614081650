import { createUseStyles } from 'react-jss';
import { base, colors, containerSize, queries, spacing } from '../../styles';

export const articlesScrollerStyles = createUseStyles({
  container: {
    maxWidth: `calc((100vw - ${containerSize.normal}) / 2 + ${containerSize.normal})`,
    width: 'auto',
    marginLeft: 'auto',
    paddingLeft: spacing.normal,

    [queries.md]: {
      paddingLeft: spacing.small,
    },
    '& *': {
      textDecoration: 'none !important',
    },
    overflowX: 'visible',
  },
  card: {
    overflow: 'hidden',
    position: 'relative',
    marginRight: base(),


    minHeight: base(12),

    '& *': {
      color: colors.white,
    },

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  cardInnerContainer: {
    padding: base(),
  },
  cardBackgroundContainer: {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',

    transition: 'all 0.3s ease-in',

    display: 'block',
    width: '100%',
    height: '100%',

    position: 'absolute',

    '&:hover': {
      transform: 'scale(1.05)',
    },

    zIndex: -1,

  },
  sliderTrack: {
    '&::-webkit-scrollbar': {
      height: base(0.5),
    },

    '&::-webkit-scrollbar-track': {
      background: colors.light,
    },
    paddingBottom: spacing.small,
  },
});
