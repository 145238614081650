import React, { useEffect, useState } from 'react';
import * as faIcons from '@fortawesome/free-solid-svg-icons';
import { faCircleCheck } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useIntersect from '../../hooks/useIntersect';
import { tagStyles } from './styles';
import { Button } from '../Button';

interface Props {
  children: React.ReactNode;
  aniamteDelay?: number;
  icon?: keyof typeof faIcons;
}

export const Tag: React.FC<Props> = ({
  children,
  aniamteDelay = 0,
  icon = 'faCircleCheck' as keyof typeof faIcons,
}) => {
  const styles = tagStyles();
  const [loded, setLoaded] = useState(false);
  const [checked, setChecked] = useState(false);
  const [setNode, entry] = useIntersect({ threshold: 0.5 });

  useEffect(() => {
    if (entry?.isIntersecting) {
      setTimeout(() => {
        setLoaded(true);
      }, aniamteDelay);

      setTimeout(() => {
        setChecked(true);
      }, aniamteDelay + 1500);
    }
  }, [entry?.isIntersecting, setLoaded, aniamteDelay]);

  const changeChecked = () => {
    setChecked(!checked);
    setTimeout(() => {
      setChecked(true);
    }, 1000);
  };

  return (
    <span
      className={`${styles.tag} ${loded ? styles.tagVisible : ''}`}
      ref={setNode}
    >
      {
        icon === 'faCircleCheck' ? (
          <Button
            type="link"
            onClick={() => changeChecked()}
          >
            <FontAwesomeIcon icon={checked ? faIcons.faCircleCheck : faCircleCheck} />
          </Button>
        ) : (
          <FontAwesomeIcon icon={faIcons[icon as string]} />
        )
      }
      {children}
    </span>
  );
};
