import { Cell, Grid } from '@faceless-ui/css-grid';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import { Article } from '../../../payload-types';
import { ArticleMeta } from '../ArticleMeta';
import { featuredArticleCardStyles } from './styles';

interface Props {
  article: Article;
}

export const FeaturedArticleCard: React.FC<Props> = ({ article }) => {
  const styles = featuredArticleCardStyles();

  return (
    <Link
      href={`/article/${article.slug}`}
      className={styles.linkContainer}
    >
      <div className={styles.container}>
        <Grid>
          <Cell cols={6}>
            {article.featuredImage && typeof article.featuredImage === 'object' && (
              <div className={styles.imageContainer}>
                <Image
                  src={article.featuredImage.sizes.card.url || article.featuredImage.url}
                  alt={article.featuredImage.alt}
                  width={article.featuredImage.sizes.card.width || article.featuredImage.width}
                  height={article.featuredImage.sizes.card.height || article.featuredImage.height}
                  quality={100}
                />
              </div>
            )}
          </Cell>
          <Cell cols={6}>
            <div className={styles.content}>
              <div className={styles.categoryTags}>
                {article.categories.map((category) => (
                  <span
                    key={category.slug}
                    className={styles.categoryTag}
                  >
                    {category.title}
                  </span>
                ))}
              </div>
              <h2 className={styles.articleTitle}>{article.title}</h2>
              <p>{article.description}</p>
              <ArticleMeta article={article} />
            </div>
          </Cell>
        </Grid>
      </div>
    </Link>
  );
};
