import { Collapsible, CollapsibleContent, CollapsibleGroup, CollapsibleToggler } from '@faceless-ui/collapsibles';
import { Cell, Grid } from '@faceless-ui/css-grid';
import { faClose, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { BackgroundColor, Container, RichText, Spacing } from '../../components';
import { faqStyles } from './styles';

interface Props {
  faq: {
    backgroundColor?: 'none' | 'dark' | 'light';
    spacings: {
      top?: 'none' | 'small' | 'medium' | 'large';
      bottom?: 'none' | 'small' | 'medium' | 'large';
    };
    title?: string;
    description?: {
      [k: string]: unknown;
    }[];
    faqs: {
      question?: string;
      answer?: {
        [k: string]: unknown;
      }[];
      id?: string;
    }[];
  };
  id?: string;
  blockName?: string;
  blockType: 'faq';
}

export const Faq: React.FC<Props> = ({
  faq,
}) => {
  const styles = faqStyles();
  const router = useRouter();

  useEffect(() => {
    // reset state
  }, [router.asPath]);

  return (
    <BackgroundColor backgroundColor={faq.backgroundColor}>
      <Spacing spacing={faq.spacings}>
        <Container className={styles.container}>
          <Grid>
            <Cell cols={5}>
              <h2>{faq.title}</h2>
              <RichText content={faq.description} />
            </Cell>
            <Cell cols={7}>
              <CollapsibleGroup>
                {
                faq.faqs.map((item, index) => (
                  <div
                    key={index}
                    className={styles.faqContainer}
                  >
                    <Collapsible
                      key={item.id}
                      classPrefix="invx__faq"
                      transTime={250}
                      transCurve="ease-in"
                    >
                      <CollapsibleToggler>
                        <h3>{item.question}</h3>
                        <span className={styles.icon}>
                          <FontAwesomeIcon
                            className="invx-icon__closed-modal"
                            icon={faPlus}
                          />
                          <FontAwesomeIcon
                            className="invx-icon__open-modal"
                            icon={faClose}
                          />
                        </span>
                      </CollapsibleToggler>
                      <CollapsibleContent>
                        <RichText content={item.answer} />
                      </CollapsibleContent>
                    </Collapsible>
                  </div>
                ))
              }
              </CollapsibleGroup>

            </Cell>
          </Grid>
        </Container>
      </Spacing>
    </BackgroundColor>
  );
};
