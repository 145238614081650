import { createUseStyles } from 'react-jss';
import { base, colors, h5, spacing } from '../../styles';

export const articleCardStyles = createUseStyles({
  linkContainer: {
    textDecoration: 'none !important',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  featuredImageContainer: {
    '& img': {
      width: '100%',
      height: 'auto',
    },
  },
  categoryTags: {
    margin: `${spacing.xSmall} 0`,
    display: 'flex',
  },
  categoryTag: {
    padding: `0 ${base(0.5)}`,
    height: base(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.light,
    marginRight: spacing.xSmall,
  },
  title: {
    ...h5,
    fontWeight: 600,
  },
  spacer: {
    flex: 1,
  },
});
