import { createUseStyles } from 'react-jss';
import { spacing } from '../../../styles';

export const defaultPageHeaderStyles = createUseStyles({
  container: {
    marginTop: spacing.large,
  },
  title: {
    marginBottom: spacing.small,
  },
});
