import React from 'react';
import { Cell, Grid } from '@faceless-ui/css-grid';
import Image from 'next/image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import { compareContrastCardsStyles } from './styles';
import { Media } from '../../../payload-types';
import { BackgroundColorType, SpacingType } from '../../utilities/type';
import { BackgroundColor, Container, RichText, Spacing } from '../../components';
import { colors } from '../../styles';

interface Props {
  compareContrastCards: {
    backgroundColor?: BackgroundColorType,
    spacings: SpacingType,
    card: {
      type?: 'correct' | 'incorrect';
      image: string | Media;
      content?: {
        [k: string]: unknown;
      }[];
      id?: string;
    }[];
  };
  id?: string;
  blockName?: string;
  blockType: 'compareContrastCards';
}

export const CompareContrastCards: React.FC<Props> = ({
  compareContrastCards,
}) => {
  const styles = compareContrastCardsStyles();

  return (
    <BackgroundColor backgroundColor={compareContrastCards.backgroundColor}>
      <Spacing
        spacing={compareContrastCards.spacings}
      >
        <Container className={styles.container}>
          <Grid>
            {
              compareContrastCards.card.map(({ image, content, type, id }) => (
                <Cell
                  key={id}
                  cols={compareContrastCards.card.length === 3 ? 4 : 6}
                  className={styles.cell}
                >
                  <div className={styles.cardContainer}>
                    <div className={styles.iconContainer}>
                      <span
                        className={styles.icon}
                        style={{
                          backgroundColor: type === 'correct' ? colors.success : colors.red,
                        }}
                      >
                        <FontAwesomeIcon
                          icon={type === 'correct' ? faCheck : faTimes}
                        />
                      </span>
                    </div>
                    {
                      typeof image === 'object' ? (
                        <Image
                          src={image.sizes.card.url || image.url}
                          alt={image.alt}
                          width={image.sizes.card.width || image.width}
                          height={image.sizes.card.height || image.height}
                          quality={100}
                          className={styles.image}
                        />
                      ) : null
                    }

                    <div
                      className={styles.descriptionContainer}
                      style={{
                        borderColor: type === 'correct' ? '#00A99D' : '#FF0000',
                      }}
                    >
                      <RichText content={content} />

                    </div>
                  </div>
                </Cell>
              ))
            }
          </Grid>
        </Container>
      </Spacing>
    </BackgroundColor>
  );
};
