import React from 'react';
import { Logo } from '../graphics';
import { innovixxLogoStyles } from './styles';

interface Props {
  light?: boolean;
  className?: string;
}

export const InnovixxLogo: React.FC<Props> = ({ light = false, className }) => {
  const styles = innovixxLogoStyles({
    light,
  });

  return (
    <div className={`${styles.container} ${className}`}>
      <Logo />
    </div>
  );
};
