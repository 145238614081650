import { createUseStyles } from 'react-jss';
import { colors, h2, spacing } from '../../../styles';

export const articleHeaderStyles = createUseStyles({
  container: {
    marginTop: spacing.small,
  },
  categoriesContainer: {
    marginTop: spacing.normal,
    marginBottom: spacing.small,
    '& button': {
      background: colors.light,
      marginRight: spacing.small,
      '& span': {
        color: colors.lightGrey,
      },
    },
  },
  featuredImage: {
    marginTop: spacing.normal,
    width: '100%',
    height: 'auto',
  },
  title: {
    marginBottom: spacing.small,
    ...h2,
  },
});
