import { createUseStyles } from 'react-jss';
import { base, colors, h5, spacing } from '../../styles';

export const caseStudyCardStyles = createUseStyles({
  linkContainer: {
    textDecoration: 'none !important',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',

    padding: spacing.xSmall,
    borderRadius: base(0.5),
    '&:hover': {
      backgroundColor: colors.light,
    },
    transition: 'background-color 0.3s ease-in-out',
  },
  featuredImageContainer: {
    '& img': {
      width: '100%',
      height: 'auto',
    },
  },
  categoryTags: {
    margin: `${spacing.xSmall} 0`,
    display: 'flex',
  },
  categoryTag: {
    padding: `0 ${base(0.5)}`,
    height: base(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.light,
    marginRight: spacing.xSmall,
  },
  title: {
    ...h5,
    fontWeight: 600,
  },
  spacer: {
    flex: 1,
  },
  overtitle: {
    textTransform: 'uppercase',
  },
});
