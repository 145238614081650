import { base } from '../base';

export const spacing = {
  xSmall: base(0.5),
  small: base(),
  normal: base(2),
  medium: base(3),
  large: base(4),
  xLarge: base(8),
};


export const headerHeight = base(4);
export const mobileHeaderHeight = base(2);

export const borderRadius = base(0.3);

export const containerSize = {
  large: base(82),
  normal: base(68),
  small: base(50),
};
