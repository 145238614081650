import { createUseStyles } from 'react-jss';
import { base, body, colors, h5, spacing } from '../../../styles';

export const contactFormStyles = createUseStyles({
  container: {
    maxWidth: '600px',
    margin: '0 auto',
    height: '100%',
    position: 'relative',
    display: 'block',
  },
  selectContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

    '& button': {
      padding: `${base(0.5)} 0`,
      width: '100%',
      margin: `0 ${base(0.2)}`,
      '& span': {
        alignItems: 'center',
        justifyContent: 'center',
      },
    },

    '& button.secondary': {
      background: colors.light,
    },
    marginBottom: spacing.normal,
  },
  fieldLabel: {
    ...h5,
    marginBottom: spacing.small,
    fontWeight: 600,
  },
  successContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    justifyContent: 'center',
    gap: spacing.xSmall,
  },
  row: {
    rowGap: '0 !important',
  },
  input: {
    outline: 'none',
    background: 'none',

    width: `calc(100% - ${base(2)})`,

    ...body,

    padding: `${base(0.5)} ${base()}`,
    border: `1px solid ${colors.lightGrey}`,

    marginBottom: spacing.small,
  },
  privacyPolicyText: {
  },
  submitContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  error: {
    textAlign: 'center',
    marginTop: spacing.small,
    color: colors.red,
  },
  successIcon: {
    justifyContent: 'center',
  },
  success: {
    textAlign: 'center',
    marginTop: spacing.small,
    color: colors.success,
  },
});
