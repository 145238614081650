import React, { useEffect, useState } from 'react';
import { Media } from '../../../payload-types';
import { BackgroundColor } from '../../components';
import { BackgroundColorType, LinkType } from '../../utilities/type';
import { fadeScrollSectionsStyles } from './styles';
import { FadeScrollSectionsContent } from './components';
import useIntersect from '../../hooks/useIntersect';

interface Props {
  fadeScrollSections: {
    backgroundColor?: BackgroundColorType
    items: {
      content?: {
        [k: string]: unknown;
      }[];
      buttons: {
        button: {
          type?: 'primary' | 'secondary';
          link: LinkType;
        };
        id?: string;
      }[];
      hasImage?: boolean;
      image: {
        imageWidth: 'half' | 'third';
        image: string | Media;
      };
      id?: string;
    }[];
  };
  id?: string;
  blockName?: string;
  blockType: 'fadeScrollSections';
}

export const FadeScrollSections: React.FC<Props> = ({
  fadeScrollSections,
}) => {
  const styles = fadeScrollSectionsStyles();
  const [loaded, setLoaded] = useState(false);
  const [setNode, entry] = useIntersect({
    threshold: 0.1,
  });

  useEffect(() => {
    setLoaded(entry?.isIntersecting);
  }, [entry?.isIntersecting]);

  return (
    <div ref={setNode}>
      <BackgroundColor backgroundColor={fadeScrollSections.backgroundColor}>
        <div
          className={styles.container}
        >
          {
          fadeScrollSections.items.map((item, index) => (
            <FadeScrollSectionsContent
              item={item}
              isDark={fadeScrollSections.backgroundColor === 'dark'}
              key={index}
              totalItems={fadeScrollSections.items.length}
              currentIndex={index}
              allowEntry={loaded}
            />
          ))
        }
        </div>
      </BackgroundColor>
    </div>

  );
};
