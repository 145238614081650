import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRouter } from 'next/router';
import Form, { Field, useForm } from 'rc-field-form';
import React from 'react';
import { searchFormStyles } from './styles';

export const SearchForm: React.FC = () => {
  const styles = searchFormStyles();
  const router = useRouter();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = (values: any) => {
    if (values.search) {
      router.query.search = values.search;
      router.push({
        pathname: router.pathname,
        query: router.query,
      });
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.slice(-1) === ' ') {
      form.submit();
    }
  };

  const [form] = useForm();

  return (
    <div className={styles.container}>
      <Form
        form={form}
        onFinish={onSubmit}
      >
        <Field name="search">
          <div className={styles.inputContainer}>
            <FontAwesomeIcon
              icon={faSearch}
              className={styles.searchIcon}
            />
            <input
              placeholder="Search..."
              onChange={onChange}
            />
          </div>
        </Field>
      </Form>
    </div>
  );
};
