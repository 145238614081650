import Link from 'next/link';
import React from 'react';
import { Button } from '../Button';
import { errorStyles } from './styles';

interface Props {
  title?: string;
  description?: string;
}

export const Error: React.FC<Props> = ({
  title = 'Error',
  description = 'An error has occurred, please try again later.',
}) => {
  const styles = errorStyles();

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>{title}</h1>
      <p className={styles.text}>{description}</p>
      <Link href="/">
        <Button
          className={styles.button}
          type="secondary"
        >
          Go Home
        </Button>
      </Link>
    </div>
  );
};
