import { createUseStyles } from 'react-jss';
import { colors, queries, spacing } from '../../../styles';

export const backgroundColorStyles = createUseStyles({
  container: {
    backgroundColor: `${colors.dark} !important`,

    '& h2': {
      color: colors.white,
      '& span': {
        color: colors.white,
      },
    },

    '& p': {
      color: colors.white,
    },

    '& span': {
      color: colors.white,
    },

    [queries.md]: {
      paddingTop: spacing.medium,
    },
  },
  lightContainer: {
    backgroundColor: `${colors.light} !important`,
  },
});
