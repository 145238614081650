import { LINK_FIELD, MEDIA_FIELD } from '../fields';
import { SPACING_FIELD } from '../fields/spacing';

export const CONTENT = `
  ... on Content {
    id
    blockName
    blockType
    content {
      backgroundColor
      spacings {
        ${SPACING_FIELD}
      }
      reverseOrderMobile
      columns {
        width
        alignment
        verticalAlignment
        content
        id
      }
    }
  }
`;

export const IMAGE = `
  ... on Image {
    image {
      backgroundColor
      spacings {
        ${SPACING_FIELD}
      }
      imageWidth
      image {
        ${MEDIA_FIELD}
      }
      caption
    }
    id
    blockName
    blockType
  }
`;

export const IMAGE_CONTENT = `
  ... on ImageContent {
    imageContent {
      backgroundColor
      spacings {
        ${SPACING_FIELD}
      }
      imagePosition
      imageWidth
      image {
        ${MEDIA_FIELD}
      }
      content
    }
    id
    blockName
    blockType
  }
`;

export const FAQ = `
  ... on Faq {
    faq {
      backgroundColor
      spacings {
        ${SPACING_FIELD}
      }
      title
      description
      faqs {
        question
        answer
        id
      }
    }
    id
    blockName
    blockType
  }
`;

export const CARD_SCROLLER = `
  ... on CardScroller {
    cardScroller {
      backgroundColor
      spacings {
        ${SPACING_FIELD}
      }
      hasLeftContent
      leftContent
      cards {
        content
        id
      }
    }
    id
    blockName
    blockType
  }
`;

export const BUTTONS = `
  ... on Buttons {
    buttons {
      backgroundColor
      spacings {
        ${SPACING_FIELD}
      }
      alignment
      buttons {
        button {
          type
          link {
            ${LINK_FIELD}
          }
        }
        id
      }
    }
    id
    blockName
    blockType
  }
`;

export const TESTIMONIALS = `
  ... on Testimonials {
    testimonials {
      backgroundColor
      spacings {
        ${SPACING_FIELD}
      }
      testimonials {
        id
        logo {
          ${MEDIA_FIELD}
        }
        content
      }
    }
    id
    blockName
    blockType
  }
`;

export const CTA = `
  ... on Cta {
    cta {
      backgroundColor
      spacings {
        ${SPACING_FIELD}
      }
      title
      description
      buttons {
        id
        button {
          type
          link {
            ${LINK_FIELD}
          }
        }
      }
    }
    id
    blockName
    blockType
  }
`;

export const SERVICE_SCROLLER = `
  ... on ServiceScroller {
    services {
      slug
      featuredImage {
        ${MEDIA_FIELD}
      }
      overTitle
      title
      description
    }
    id
    blockName
    blockType
  }
`;

export const ARTICLES_SCROLLER = `
  ... on ArticlesScroller {
    articlesScroller {
      backgroundColor
      spacings {
        ${SPACING_FIELD}
      }
      title
      articles {
        id
        slug
        title
        featuredImage {
          ${MEDIA_FIELD}
        }
        date
      }
    }
    id
    blockName
    blockType
  }
`;

export const FADE_SCROLL_SECTIONS = `
  ... on FadeScrollSections {
    fadeScrollSections {
      backgroundColor
      items {
        content
        buttons {
          button {
            type
            link {
              ${LINK_FIELD}
            }
          }
          id
        }
        hasImage
        image {
          imageWidth
          image {
            ${MEDIA_FIELD}
          }
        }
        id
      }
    }
    id
    blockName
    blockType
  }
`;

export const COMPARE_CONTRAST_CARDS = `
  ... on CompareContrastCards {
    compareContrastCards {
      backgroundColor
      spacings {
        ${SPACING_FIELD}
      }
      card {
        id
        type
        content
        image {
          ${MEDIA_FIELD}
        }
      }
    }
    id
    blockName
    blockType
  }
`;
