interface Props {
  name: string;
  email: string;
  message: string;
  phone?: string;
  platform?: string;
  timeline?: string;
}

const CREATE_CONTACT_SUBMISSION = `
  mutation Mutation($data: mutationContactSubmissionInput!) {
    createContactSubmission(data: $data) {
      id
    }
  }
`;

export const createContactSubmission = async (input: Props): Promise<boolean> => {
  const { data } = await fetch(
    `${process.env.NEXT_PUBLIC_SERVER_URL}/api/graphql`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      next: {
        revalidate: 600,
      },
      body: JSON.stringify({
        query: CREATE_CONTACT_SUBMISSION,
        variables: {
          data: input,
        },
      }),
    },
  ).then((res) => res.json());

  if (data && data.createContactSubmission && data.createContactSubmission.id) {
    return true;
  }

  return false;
};
