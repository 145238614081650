import { createUseStyles } from 'react-jss';
import { base, colors, spacing } from '../../styles';

export const compareContrastCardsStyles = createUseStyles({
  container: {

  },
  cell: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  cardContainer: {
    padding: `${base(1)} ${base(1.5)}`,
    background: colors.light,

    border: `1px solid ${colors.dark}`,
    borderRadius: spacing.xSmall,
    height: '100%',
  },
  descriptionContainer: {
    paddingLeft: spacing.xSmall,
    borderLeft: `4px solid ${colors.dark}`,
    '& *': {
      fontWeight: 600,
    },
  },
  image: {
    width: '100%',
    height: 'auto',
    marginBottom: spacing.normal,

    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
    borderRadius: spacing.xSmall,
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'flex-end',

    marginBottom: spacing.small,
  },
  icon: {
    width: base(1.5),
    height: base(1.5),
    '& svg': {
      width: base(1),
      height: base(1),
      color: colors.white,
    },

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
  },
});
