import Link from 'next/link';
import React from 'react';
import { Category } from '../../../../payload-types';
import { Button } from '../../Button';
import { SearchForm } from '../../forms';
import { Container } from '../Container';
import { articleArchiveHeaderStyles } from './styles';

interface Props {
  categories: Category[]
  selectedCategory?: string
}

export const ArticleArchiveHeader: React.FC<Props> = ({
  categories,
  selectedCategory,
}) => {
  const styles = articleArchiveHeaderStyles();

  return (
    <div className={styles.container}>
      <Container>
        <div className={styles.searchFormContainer}>
          <SearchForm />
        </div>
        <span className={styles.overTitle}>Updates & Insights</span>
        <h1 className={styles.title}>The Latest</h1>
        <div className={styles.categoryButtonsContainer}>
          <Link href="/articles">
            <Button
              type={selectedCategory ? 'secondary' : 'primary'}
              disableAnimation
            >
              All Categories
            </Button>
          </Link>
          {
            categories.map((category) => (
              <Link
                key={category.id}
                href={`/articles/${category.slug}`}
              >
                <Button
                  type={selectedCategory === category.id ? 'primary' : 'secondary'}
                  key={category.slug}
                  disableAnimation
                >
                  {category.title}
                </Button>
              </Link>

            ))
          }
        </div>
      </Container>
    </div>
  );
};
