import { createUseStyles } from 'react-jss';
import { base, colors } from '../../styles';
import { queries } from '../../styles/queries';

export const headerNoticeStyles = createUseStyles({
  linkContainer: {
    textDecoration: 'none !important',
  },
  container: {
    height: base(2),
    background: colors.dark,
    '& p': {
      color: colors.white,
      margin: 0,
    },
  },
  innerContainer: {
    height: base(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    [queries.sm]: {
      '& > *:not(:first-child)': {
        display: 'none',
      },
    },
  },
});
