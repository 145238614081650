import React from 'react';
import { buttonStyles } from './styles';

interface Props {
  type?: 'primary' | 'secondary' | 'link';
  htmlType?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
  title?: string;
  children: React.ReactNode;
  dark?: boolean;
  icon?: React.ReactNode;
  style?: React.CSSProperties;
  loading?: boolean;
  disableAnimation?: boolean;
}

export const Button: React.FC<Props> = ({
  type = 'primary',
  htmlType = 'button',
  disabled = false,
  onClick,
  className,
  title,
  children,
  dark,
  icon,
  style,
  loading = false,
  disableAnimation = false,
}) => {
  const styles = buttonStyles({
    dark,
    disableAnimation,
  });

  if (type === 'link') {
    return (
      <button
        className={`${styles.linkButton} ${className || ''}`}
        // eslint-disable-next-line react/button-has-type
        type={htmlType}
        disabled={disabled}
        onClick={onClick}
        title={title}
        style={style}
      >
        {children}
      </button>
    );
  }

  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={htmlType}
      disabled={disabled}
      onClick={onClick}
      className={`${styles.button} ${styles[type]} ${type} ${className || ''}`}
      title={title}
      style={style}
    >
      <span
        className={`${styles.buttonInnerSpan}`}
      >
        {children}
        {icon && !loading && (
          <span className={`button__icon ${styles.buttonIcon}`}>{icon}</span>
        )}
        {loading && (
          <span className={styles.loadingIcon}>
            {/* svg circle notch */}
            <svg
              className="button__loading-icon"
              viewBox="0 0 100 100"
              preserveAspectRatio="xMidYMid"
            >
              <circle
                cx="50"
                cy="50"
                fill="none"
                stroke="#fff"
                strokeWidth="10"
                r="35"
                strokeDasharray="164.93361431346415 56.97787143782138"
                transform="rotate(0 50 50)"
              >
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  repeatCount="indefinite"
                  dur="1s"
                  values="0 50 50;360 50 50"
                  keyTimes="0;1"
                />
              </circle>
            </svg>
          </span>
        )}
      </span>
      {!disableAnimation && (
        <React.Fragment>
          <div className={`${styles.topBorder} invx-button__top-border`} />
          <div className={`${styles.rightBorder} invx-button__right-border`} />
          <div className={`${styles.leftBorder} invx-button__left-border`} />
          <div className={`${styles.bottomLeftBorder} invx-button__bottom-left-border`} />
          <div className={`${styles.bottomRightBorder} invx-button__bottom-right-border`} />
        </React.Fragment>
      )}
    </button>
  );
};
