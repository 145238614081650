import { useWindowInfo, WindowInfoProvider } from '@faceless-ui/window-info';
import { ScrollInfoProvider } from '@faceless-ui/scroll-info';
import { GridProvider } from '@faceless-ui/css-grid';
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ModalContainer, ModalProvider } from '@faceless-ui/modal';
import { Container } from '../layouts';
import { base, breakpoints } from '../../styles';

interface Props {
  children: React.ReactNode;
}

const Context = createContext<number>(0);

export const FacelessContainerWithProviders: React.FC<Props> = ({
  children,
}) => {
  const { width: windowWidth } = useWindowInfo();
  const ref = useRef(null);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth(ref?.current?.offsetWidth);
  }, [windowWidth, ref]);

  return (
    <ScrollInfoProvider>
      <WindowInfoProvider
        breakpoints={{
          s: `${breakpoints.sm}`,
          m: `${breakpoints.md}`,
          l: `${breakpoints.lg}`,
          xl: `${breakpoints.xl}`,
        }}
      >
        <Context.Provider value={width}>
          <GridProvider
            rowGap={{
              s: base(2),
              m: base(2),
              l: base(2),
              xl: base(2),
            }}
            colGap={{
              s: base(2),
              m: base(2),
              l: base(2),
              xl: base(2),
            }}
            cols={{
              s: 4,
              m: 6,
              l: 12,
              xl: 12,
            }}
            breakpoints={{
              s: breakpoints.sm,
              m: breakpoints.md,
              l: breakpoints.lg,
            }}
          >
            <ModalProvider
              zIndex={15}
              transTime={250}
            >
              <ModalContainer />
              {children}
            </ModalProvider>
          </GridProvider>
          <Container>
            <div ref={ref} />
          </Container>
        </Context.Provider>
      </WindowInfoProvider>
    </ScrollInfoProvider>
  );
};

export const useGridContainerWidth = (): number => useContext(Context);
