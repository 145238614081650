import React from 'react';
import { containerStyles } from './styles';

interface Props {
  children: React.ReactNode;
  className?: string;
  containerSize?: 'large' | 'normal' |'small';
}

export const Container: React.FC<Props> = ({
  children,
  className,
  containerSize = 'normal',
}) => {
  const styles = containerStyles({
    containerSize,
  });

  return <div className={`${styles.container} ${className}`}>{children}</div>;
};
