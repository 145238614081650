import { createUseStyles } from 'react-jss';
import { spacing } from '../../styles';

export const serviceScrollerStyles = createUseStyles({
  container: {
    marginTop: spacing.xLarge,
    marginBottom: spacing.xLarge,
  },
  serviceStickyContianer: {

  },
});
