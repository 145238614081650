import { Collapsible, CollapsibleContent, CollapsibleToggler } from '@faceless-ui/collapsibles';
import { Modal, useModal } from '@faceless-ui/modal';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { Header } from '../../../payload-types';
import { spacing } from '../../styles';
import { AppLink } from '../AppLink';
import { Button } from '../Button';
import { Container } from '../layouts';
import { mobileHeaderStyles } from './styles';

interface Props {
  header: Header
}

const slug = 'mobile-header-modal';

export const MobileHeader: React.FC<Props> = ({
  header,
}) => {
  const styles = mobileHeaderStyles();

  const router = useRouter();
  const { closeModal } = useModal();

  useEffect(() => {
    closeModal(slug);
  }, [router.asPath, closeModal]);

  return (
    <Modal
      className={styles.container}
      slug={slug}
    >
      <Container className={styles.innerContainer}>
        <div className={styles.navContainer}>
          {
          header.mainMenu?.map((item) => (
            <React.Fragment key={item.id}>
              {
                item.type === 'link' ? (
                  <AppLink
                    link={item.link}
                    className={styles.link}
                  />
                ) : (
                  <Collapsible
                    key={item.id}
                    classPrefix="invx__dropdown"
                    transTime={250}
                    transCurve="ease-in"
                  >
                    {
                      ({ isOpen }) => (
                        <React.Fragment>
                          <CollapsibleToggler className={styles.dropdownButtonContainer}>
                            <span className={styles.dropdownLink}>{item.dropdown.title}</span>
                            <span className={`${styles.caretIcon} ${isOpen ? styles.openCaretIcon : ''}`}>
                              <FontAwesomeIcon
                                icon={faCaretDown}
                              />
                            </span>

                          </CollapsibleToggler>
                          <CollapsibleContent style={{
                            marginBottom: spacing.small,
                          }}
                          >
                            <div className={styles.dropdownContainer}>
                              {
                                item.dropdown?.links.map((link) => (
                                  <AppLink
                                    link={link.link}
                                    key={link.id}
                                    className={styles.dropdownInnerLink}
                                  />
                                ))
                              }
                            </div>
                          </CollapsibleContent>
                        </React.Fragment>
                      )
                    }
                  </Collapsible>
                )
              }
            </React.Fragment>
          ))
        }
        </div>
        <Button
          type={header.ctaButton.button.type}
          dark
        >
          <AppLink link={header.ctaButton.button.link} />
        </Button>
      </Container>
    </Modal>
  );
};
