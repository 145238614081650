import React from 'react';
import Script from 'next/script';

export const AtlassianWidget: React.FC = () => {
  if (
    process.env.NODE_ENV !== 'production'
    || !process.env.NEXT_PUBLIC_ATLASSIAN_WIDGET_KEY
  ) {
    return null;
  }

  return (
    <React.Fragment>
      <Script
        src="https://jsd-widget.atlassian.com/assets/embed.js"
        strategy="lazyOnload"
        data-jsd-embedded
        data-key={`${process.env.NEXT_PUBLIC_ATLASSIAN_WIDGET_KEY}`}
        data-base-url="https://jsd-widget.atlassian.com"
      />
    </React.Fragment>
  );
};
