import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import * as faIcons from '@fortawesome/free-solid-svg-icons';
import { iconStyles } from './styles';
import { constructClassName } from '../../../../utilities';

interface Props {
  icon: string;
  size: string;
  children?: React.ReactNode;
  className?: string;
}

export const Icon: React.FC<Props> = ({
  icon,
  size,
  children,
  className,
}) => {
  const styles = iconStyles();
  return (
    <div className={`${constructClassName([styles.icon, className])} ${styles[`icon-${size}`]}`}>
      <span>
        <FontAwesomeIcon icon={faIcons[icon]} />
      </span>
      <div>
        {children}
      </div>
    </div>

  );
};
