import { createUseStyles } from 'react-jss';
import { colors, h2, h4, htmlFontSize, mobileHeaderHeight, spacing } from '../../styles';

export const mobileHeaderStyles = createUseStyles({
  container: {
    width: '100vw',
    height: `calc(100vh - ${mobileHeaderHeight})`,
    top: mobileHeaderHeight,

    margin: '0 !important',
    padding: '0 !important',

    border: 0,

    background: colors.dark,

    '& *': {
      color: colors.white,
      textDecoration: 'none',
    },
  },
  innerContainer: {
    marginTop: spacing.medium,
  },
  navContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  link: {
    ...h2,
    color: colors.white,

    textDecoration: 'none !important',
  },
  dropdownLink: {
    ...h2,
    color: colors.white,

    marginBottom: 0,
  },
  dropdownButtonContainer: {

    background: 'transparent',
    border: 0,
    padding: 0,

    textAlign: 'left',

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

    '& svg': {
      width: htmlFontSize,
    },
  },
  dropdownContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: spacing.small,
  },
  dropdownInnerLink: {
    ...h4,
    color: colors.white,
    paddingTop: spacing.xSmall,
    paddingBottom: spacing.xSmall,

    textDecoration: 'none !important',

    borderTop: `1px solid ${colors.white}`,
    '&:last-child': {
      borderBottom: `1px solid ${colors.white}`,
    },
  },
  caretIcon: {
    '& svg': {
      transition: 'all 0.3s ease-in-out',
    },
  },
  openCaretIcon: {
    '& svg': {
      transform: 'rotate(-180deg)',
    },
  },
});
