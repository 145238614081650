import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { Button } from '../Button';
import { cookiesBannerStyles } from './styles';

export const CookiesBanner: React.FC = () => {
  const styles = cookiesBannerStyles();
  const { asPath } = useRouter();
  const [isVisible, setIsVisible] = useState(false);

  const onClose = () => {
    window.document.cookie = 'cookiesConsented=true;max-age=31536000';
    setIsVisible(false);
  };

  useEffect(() => {
    if (window.document.cookie.indexOf('cookiesConsented=true') !== -1) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  }, [setIsVisible, asPath, isVisible]);

  if (!isVisible) return null;

  return (
    <div
      className={styles.container}
    >
      <p className={styles.text}>
        This website uses cookies to ensure you get the best experience on our website.
        {' '}
        <Link
          href="/privacy-policy"
          title="See our privacy policy"
        >
          Learn More
        </Link>
      </p>
      <Button
        className={styles.button}
        onClick={onClose}
      >
        Got It
      </Button>
    </div>
  );
};
