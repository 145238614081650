import { createUseStyles } from 'react-jss';

export const contentStyles = createUseStyles({
  container: {
  },
  'align-center': {
    textAlign: 'center',
    '@global': {
      p: {
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
  },
  'align-right': {
    textAlign: 'right',
  },
  'verticalAlign-top': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  'verticalAlign-center': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  'verticalAlign-bottom': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
});
