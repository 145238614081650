import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { Grid, Cell } from '@faceless-ui/css-grid';
import Link from 'next/link';
import { ModalToggler } from '@faceless-ui/modal';
import { useRouter } from 'next/router';
import { Button } from '../../Button';
import { FullLockup } from '../../graphics';
import { Container } from '../Container';
import { headerStyles } from './styles';
import { HeaderNotice } from '../../HeaderNotice';
import { Header as HeaderType } from '../../../../payload-types';
import { AppLink } from '../../AppLink';
import { MobileHeader } from '../../MobileHeader';
import { HeaderCaseStudyCard } from '../../HeaderCaseStudyCard';

interface Props {
  header: HeaderType
}

export const Header: React.FC<Props> = ({
  header,
}) => {
  const [dropdownContents, setDropdownContents] = React.useState<HeaderType['mainMenu'][0]['dropdown'] | null>(null);
  const [dropdownOpen, setDropdownOpen] = React.useState(false);

  const { asPath } = useRouter();

  useEffect(() => {
    setDropdownOpen(false);
    setDropdownContents(null);
  }, [asPath]);

  const toggleDropdown = (data: HeaderType['mainMenu'][0]['dropdown'] | boolean) => {
    if (typeof data !== 'object') {
      setDropdownOpen(false);
      return;
    }
    setDropdownContents(data);
    setDropdownOpen(true);
  };

  const styles = headerStyles();

  return (
    <React.Fragment>
      <HeaderNotice data={header.topNoticeBar} />
      <div
        className={styles.outerContainer}
        onMouseLeave={() => setDropdownOpen(false)}
      >
        <div className={styles.container}>
          <Container
            containerSize="large"
            className={styles.innerContainer}
          >
            <Link
              href="/"
              title="Innovixx Home"
            >
              <FullLockup className={styles.logo} />
            </Link>
            <div className={styles.menuContainer}>
              <ul>
                {
                  header.mainMenu && header.mainMenu.map((item) => (item.type === 'link' ? (
                    <li
                      key={item.id}
                      onMouseEnter={() => toggleDropdown(false)}
                    >
                      <AppLink
                        link={item.link}
                        key={item.id}
                      >

                        <span>{item.link.label}</span>

                      </AppLink>
                    </li>
                  ) : (
                    <li
                      key={item.id}
                      onMouseEnter={() => toggleDropdown(item.dropdown)}
                    >
                      <span>{item.dropdown.title}</span>
                    </li>
                  )))
                }
              </ul>
            </div>
            <span className={styles.mobileMenuButtonContainer}>
              <ModalToggler slug="mobile-header-modal">
                <FontAwesomeIcon icon={faBars} />
              </ModalToggler>
            </span>
            {
              (header.ctaButton && header.ctaButton.button.link.label) && (
                <div className={styles.headerCtaButton}>
                  <AppLink
                    link={header.ctaButton.button.link}
                  >
                    <Button>
                      {header.ctaButton.button.link.label}
                    </Button>
                  </AppLink>
                </div>
              )
            }

          </Container>
        </div>
        {
          dropdownContents && (
            <div
              className={`${styles.dropdownMenu} ${dropdownOpen ? styles.dropdownMenuOpen : ''}`}
            >
              <Container
                containerSize="large"
              >
                <div className={styles.dropdownMenuInnerContainer}>
                  <Grid>
                    <Cell
                      cols={9}
                      colsM={6}
                    >
                      <div className={styles.dropDownMenuOptionsContainer}>
                        {
                          dropdownContents?.links.map((option) => (
                            <Button
                              type="link"
                              onClick={() => setDropdownOpen(false)}
                              className={styles.dropDownMenuOptionButton}
                              key={option.id}
                            >
                              <AppLink
                                link={option.link}
                                key={option.id}
                              >
                                <div className={styles.dropDownMenuOption}>
                                  <div>
                                    <h3>{option.link.label}</h3>
                                    <p>{option.description}</p>
                                  </div>
                                </div>
                              </AppLink>
                            </Button>

                          ))
                        }
                      </div>
                    </Cell>
                    {
                      // TODO: dynamically render study card
                      true && (
                        <Cell
                          cols={3}
                          colsM={0}
                        >
                          <HeaderCaseStudyCard
                            featuredCaseStudy={dropdownContents.featuredCaseStudy}
                          />
                        </Cell>
                      )
                    }
                  </Grid>
                </div>
              </Container>
            </div>
          )
        }
      </div>
      <MobileHeader header={header} />
    </React.Fragment>
  );
};
