import { createUseStyles } from 'react-jss';

export const imageStyles = createUseStyles({
  container: {
    '& img': {
      width: '100%',
      margin: '0 auto',
      height: 'auto',
    },
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fullWidthImage: {
    width: '100%',
    height: 'auto',
  },
});
