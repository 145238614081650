import React from 'react';
import NextImage from 'next/image';
import { BackgroundColor, Container, RichText, Spacing } from '../../components';
import { Media } from '../../../payload-types';
import { imageStyles } from './styles';
import { BackgroundColorType, SpacingType } from '../../utilities/type';

interface Props {
  image: {
    backgroundColor?: BackgroundColorType;
    spacings: SpacingType;
    imageWidth: 'full' | 'wide' | 'card'
    image: string | Media;
    caption?: {
      [k: string]: unknown;
    }[];
  }
  id?: string;
  blockName?: string;
  blockType: 'image';
}

export const Image: React.FC<Props> = ({
  image,
}) => {
  const styles = imageStyles();

  if (typeof image.image === 'object') {
    return (
      <BackgroundColor backgroundColor={image.backgroundColor}>
        <Spacing spacing={image.spacings}>
          {
            image.imageWidth === 'full' ? (
              <React.Fragment>
                <NextImage
                  className={styles.fullWidthImage}
                  src={image.image.sizes.largeFeature.url || image.image.url}
                  alt={image.image.alt}
                  width={image.image.sizes.largeFeature.width || image.image.width}
                  height={image.image.sizes.largeFeature.height || image.image.height}
                  quality={100}
                />
                <Container className={styles.container}>
                  {image.caption && (
                  <RichText
                    content={image.caption}
                  />
                  )}
                </Container>
              </React.Fragment>
            ) : (
              <Container className={image.imageWidth === 'card' ? styles.cardContainer : styles.container}>
                <NextImage
                  src={image.imageWidth === 'card' ? image.image.sizes.card.url : image.image.sizes.feature.url || image.image.url}
                  alt={image.image.alt}
                  width={image.imageWidth === 'card' ? image.image.sizes.card.width : image.image.sizes.feature.width || image.image.width}
                  height={image.imageWidth === 'card' ? image.image.sizes.card.height : image.image.sizes.feature.height || image.image.height}
                  quality={100}
                />
                {image.caption && (
                <RichText
                  content={image.caption}
                />
                )}
              </Container>
            )
          }
        </Spacing>
      </BackgroundColor>
    );
  }

  return null;
};
