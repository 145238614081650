import React from 'react';
import NextHead from 'next/head';
import { useRouter } from 'next/router';
import { Media } from '../../../payload-types';

const defaultDescription = 'We build custom software for businesses of all sizes. Get in contact and build your digital ambitions.';
const defaultTitle = 'Innovixx';
const titleSuffix = ' | Innovixx';
const defaultOGImage = `${process.env.NEXT_PUBLIC_SERVER_URL}/images/og-image.jpg`;
// const defaultKeywords = 'NextJS, Payload CMS, boilerplate';

type Props = {
  meta: {
    title?: string;
    description?: string;
    image?: string | Media;
  };
};

export const Head: React.FC<Props> = ({ meta }) => {
  const { asPath } = useRouter();

  const getTitle = () => {
    if (meta.title) return meta.title + titleSuffix;
    return defaultTitle + titleSuffix;
  };

  return (
    <NextHead>
      <title>{getTitle()}</title>
      <link
        rel="icon"
        type="image/svg+xml"
        href="/favicon.svg"
      />
      <meta
        name="description"
        content={meta.description || defaultDescription}
      />
      {/* <meta
        name="keywords"
        content={meta.keywords || defaultKeywords}
      /> */}
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1"
      />
      <meta
        property="og:url"
        content={`${process.env.NEXT_PUBLIC_SERVER_URL}${asPath}`}
      />
      <meta
        property="og:title"
        content={meta.title || defaultTitle}
      />
      <meta
        property="og:description"
        content={meta.description || defaultDescription}
      />
      <meta
        property="twitter:title"
        content={meta.title || defaultTitle}
      />
      <meta
        name="twitter:site"
        content="@payloadcms"
      />
      <meta
        name="twitter:card"
        content="summary_large_image"
      />
      {
        meta.image && typeof meta.image === 'object' ? (
          <React.Fragment>
            <meta
              name="twitter:image"
              content={meta.image.sizes.card.url}
            />
            <meta
              property="og:image"
              content={meta.image.sizes.card.url}
            />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <meta
              name="twitter:image"
              content={defaultOGImage}
            />
            <meta
              property="og:image"
              content={defaultOGImage}
            />
          </React.Fragment>
        )
      }

    </NextHead>
  );
};
