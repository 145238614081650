import { createUseStyles } from 'react-jss';
import { spacing } from '../../styles';

export const paginationStyles = createUseStyles({
  container: {
    '& button:first-child': {
      marginRight: spacing.small,
    },
  },
});
