import { createUseStyles } from 'react-jss';
import { base, colors } from '../../styles';

interface Props {
  light: boolean;
}

export const innovixxLogoStyles = createUseStyles({
  container: ({ light }: Props) => ({
    width: base(5),
    height: base(5),
    backgroundColor: light ? colors.white : colors.dark,

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& svg': {
      fill: light ? colors.dark : colors.white,
    },
  }),
});
