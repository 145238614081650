import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import { Article } from '../../../../payload-types';
import { ArticleMeta } from '../../ArticleMeta';
import { Breadcrumb } from '../../Breadcrumb';
import { Button } from '../../Button';
import { Container } from '../Container';
import { articleHeaderStyles } from './styles';

interface Props {
  article: Article
}

export const ArticleHeader: React.FC<Props> = ({
  article,
}) => {
  const styles = articleHeaderStyles();

  return (
    <Container
      className={styles.container}
      containerSize="small"
    >
      <Breadcrumb
        links={[
          {
            label: 'Home',
            href: '/',
          },
          {
            label: 'Articles',
            href: '/articles',
          },
          {
            label: article.title,
          },
        ]}
      />
      <div className={styles.categoriesContainer}>
        {
          article.categories.map((category) => typeof category === 'object' && (
            <Link
              key={category.id}
              href={`/articles/${category.slug}`}
            >
              <Button
                type="secondary"
                key={category.id}
              >
                {category.title}
              </Button>
            </Link>
          ))
        }
      </div>
      <h1 className={styles.title}>{article.title}</h1>
      <ArticleMeta article={article} />
      {
        article.featuredImage && typeof article.featuredImage === 'object' ? (
          <Image
            src={article.featuredImage.sizes.feature.url || article.featuredImage.url}
            width={article.featuredImage.sizes.feature.width}
            height={article.featuredImage.sizes.feature.height}
            alt={article.featuredImage.alt}
            className={styles.featuredImage}
            quality={100}
          />
        ) : null
      }

    </Container>

  );
};
