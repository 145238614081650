import { createUseStyles } from 'react-jss';

interface Props {
  direction: 'left' | 'right' | 'up' | 'down';
}

export const slideInContainerStyles = createUseStyles({
  container: {
    overflow: 'hidden',
  },
  slidingContainer: ({ direction }: Props) => ({
    position: 'relative',
    transition: 'all 0.3s ease-in-out',
    transform: `translate${direction === 'left' || direction === 'right' ? 'X' : 'Y'}(${direction === 'left' || direction === 'down' ? '-' : ''}120%)`,
  }),
  slidingContainerActive: ({ direction }: Props) => ({
    transform: `translate${direction === 'left' || direction === 'right' ? 'X' : 'Y'}(0)`,
  }),
});
