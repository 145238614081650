import { Cell, Grid } from '@faceless-ui/css-grid';
import { useScrollInfo } from '@faceless-ui/scroll-info';
import Image from 'next/image';
import React, { useEffect, useState } from 'react';
import { Media } from '../../../../../payload-types';
import { Button, RichText } from '../../../../components';
import { AppLink } from '../../../../components/AppLink';
import useIntersect from '../../../../hooks/useIntersect';
import { LinkType } from '../../../../utilities/type';
import { fadeScrollSectionsContentStyles } from './styles';

interface Props {
  item: {
    content?: {
      [k: string]: unknown;
    }[];
    buttons: {
      button: {
        type?: 'primary' | 'secondary';
        link: LinkType;
      };
      id?: string;
    }[];
    hasImage?: boolean;
    image: {
      imageWidth: 'half' | 'third';
      image: string | Media;
    };
    id?: string;
  }
  isDark: boolean;
  totalItems: number;
  currentIndex: number;
  allowEntry?: boolean;
}

export const FadeScrollSectionsContent: React.FC<Props> = ({
  item,
  isDark,
  totalItems,
  currentIndex,
  allowEntry,
}) => {
  const [setNode, entry] = useIntersect({
    threshold: 0.65,
  });
  const [scrollDirection, setScrollDirection] = useState<'down' | 'up'>('down');
  const scrollInfo = useScrollInfo();

  useEffect(() => {
    if (scrollInfo) {
      if (scrollInfo.yDirection === 'down') {
        setScrollDirection('down');
      } else {
        setScrollDirection('up');
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entry?.isIntersecting]);

  const styles = fadeScrollSectionsContentStyles({
    scrollDirection,
  });

  return (
    <div
      className={`${styles.itemContainer} ${
        entry?.isIntersecting ? styles.fadeIn : ''
      } ${styles.fadeOut}`}
      ref={setNode}
    >
      <Grid
        className={`${styles.element} ${(allowEntry && entry?.isIntersecting) ? styles.elementCenter : ''}`}
      >
        <Cell cols={1}>
          <div className={styles.navScrollerContainer}>
            {
            [...Array(totalItems)].map((_, index) => (
              <span
                key={index}
                className={currentIndex === index ? styles.activeNavScrollerItem : ''}
              >
                <p> </p>
              </span>
            ))
            }
          </div>
        </Cell>
        <Cell
        // eslint-disable-next-line no-nested-ternary
          cols={item.hasImage ? item.image.imageWidth === 'half' ? 4 : 6 : 11}
          className={styles.cellContainer}
        >
          <RichText content={item.content} />
          <div className={styles.buttonContainer}>
            {
            item.buttons.map(({ button, id }) => (
              <Button
                type={button.type}
                key={id}
                dark={isDark}
              >
                <AppLink link={button.link} />
              </Button>
            ))
          }
          </div>
        </Cell>
        {
        item.hasImage && (
          <Cell
            cols={item.image.imageWidth === 'half' ? 7 : 5}
            className={styles.imageCellContainer}
          >
            {
              typeof item.image.image === 'object' && (
                <Image
                  className={styles.image}
                  src={item.image.image.sizes.feature.url || item.image.image.url}
                  alt={item.image.image.alt}
                  width={item.image.image.sizes.feature.width || item.image.image.width}
                  height={item.image.image.sizes.feature.height || item.image.image.height}
                  quality={100}
                />
              )
            }
          </Cell>
        )
      }
      </Grid>
    </div>
  );
};
