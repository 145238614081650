import { createUseStyles } from 'react-jss';
import { base, queries, spacing } from '../../../../styles';

export const serviceStickyContentStyles = createUseStyles({
  container: {
    position: 'relative',

    [queries.md]: {
      marginBottom: spacing.medium,
    },

  },
  bodyContainer: {
    position: 'fixed',
    top: '15vh',
    right: 0,
    bottom: '15vh',
    left: base(10),
    marginRight: base(10),
    pointerEvents: 'none',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    [queries.lg]: {
      left: base(3),
      marginRight: base(3),
    },
    [queries.md]: {
      left: 0,
      marginRight: 0,
      height: 'auto',

      position: 'relative',
      top: 'auto',
      bottom: 'auto',
      padding: '0 !important',
      margin: `calc(${base()} * 2) 0 0`,
    },
  },
  bodyContent: {
    visibility: 'hidden',
    opacity: 0,

    transition: 'all 0s, opacity 0.2s linear',

    [queries.md]: {
      left: '0 !important',
      marginLeft: '0 !important',
      height: 'auto',

      opacity: '1 !important',
      visibility: 'visible !important',
    },
  },
  centerBodyContent: {
    marginLeft: base(3),
    display: 'flex',
    alignItems: 'center',

  },
  visibleBodyContent: {
    zIndex: 3,
    visibility: 'visible',
    opacity: 1,
    pointerEvents: 'all',
  },
  iamgeContainer: {
    height: '70vh',
    alignItems: 'center',

    [queries.md]: {
      height: 'auto',
    },

    '& img': {
      width: '100%',
      height: '100%',
    },
  },
  tmpBox: {
    display: 'table',
    width: '100%',
    height: '500px',
    background: 'red',
  },
  overTitle: {
    textTransform: 'uppercase',
  },
});
