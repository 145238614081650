import React from 'react';
import { largeTextStyles } from './styles';

interface Props {
  children?: React.ReactNode;
}

export const LargeText: React.FC<Props> = ({
  children,
}) => {
  const styles = largeTextStyles();
  return (
    <span className={styles.largeText}>
      {children}
    </span>
  );
};
