import { createUseStyles } from 'react-jss';
import { base, body, colors, queries, spacing } from '../../../styles';

export const footerStyles = createUseStyles({
  container: {
    backgroundColor: colors.dark,
    color: colors.white,
    paddingTop: spacing.large,
  },
  logo: {
    marginRight: spacing.small,
  },
  footerLinksContainer: {
    marginBottom: base(2),
    '& > ul': {
      listStyle: 'none',
      padding: 0,

      display: 'flex',

      '& > li': {
        marginRight: spacing.normal,

        '& > a': {
          color: colors.lightGrey,
          textDecoration: 'none',

          '&:hover': {
            color: colors.white,
          },
        },
      },
    },

    [queries.sm]: {
      display: 'none',
    },
  },
  listLinksContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: spacing.normal,
    rowGap: spacing.normal,

    [queries.md]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },

    [queries.sm]: {
      gridTemplateColumns: '1fr',
    },
  },
  listLinks: {
    '& h4': {
      fontSize: body.fontSize,
      lineHeight: body.lineHeight,
      fontWeight: 600,
      marginBottom: spacing.small,
      color: colors.white,
    },

    '& > ul': {
      listStyle: 'none',
      padding: 0,
      '& > li': {
        '& > a': {
          color: colors.white,
        },
      },
    },
  },
  newsletterForm: {
    '& > h3': {
      color: colors.white,
      fontSize: body.fontSize,
      lineHeight: body.lineHeight,
      marginBottom: spacing.small,
    },
  },
  socialLinksContainer: {
    marginTop: spacing.normal,
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: spacing.small,

    '& a': {
      marginLeft: spacing.small,
      fontSize: base(1.25),
      lineHeight: base(1.5),
      color: colors.lightGrey,

      '&:hover': {
        color: colors.white,
      },
    },

    '& svg': {
      width: base(1.25),
      color: colors.lightGrey,
    },
  },
  bottomBarContainer: {
    marginBottom: spacing.small,
    borderTop: `1px solid ${colors.lightGrey}`,
    paddingTop: spacing.small,
    '& > span': {
      color: colors.white,
    },

    display: 'flex',
    justifyContent: 'space-between',

    [queries.sm]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  bottomBarLinks: {
    '& > a': {
      color: colors.lightGrey,
      textDecoration: 'none',

      '&:hover': {
        color: colors.white,
      },
      marginLeft: spacing.normal,

      [queries.sm]: {
        marginLeft: base(0.5),
      },
    },
  },
});
