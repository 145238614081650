interface Props {
  email: string;
}

const CREATE_NEWSLETTER_SUBSCRIPTION = `
  mutation CreateNewsletterSubscription($data: mutationNewsletterSubscriptionInput!) {
    createNewsletterSubscription(data: $data) {
      id
    }
  }
`;

export const createNewsletterSubscription = async (input: Props): Promise<boolean> => {
  const { data } = await fetch(
    `${process.env.NEXT_PUBLIC_SERVER_URL}/api/graphql`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      next: {
        revalidate: 600,
      },
      body: JSON.stringify({
        query: CREATE_NEWSLETTER_SUBSCRIPTION,
        variables: {
          data: input,
        },
      }),
    },
  ).then((res) => res.json());

  if (data && data.createNewsletterSubscription && data.createNewsletterSubscription.id) {
    return true;
  }

  return false;
};
