import { createUseStyles } from 'react-jss';
import { colors, queries, spacing } from '../../../styles';

export const articleArchiveHeaderStyles = createUseStyles({
  container: {
    marginTop: spacing.normal,
    paddingBottom: spacing.small,
    borderBottom: `1px solid ${colors.lightGrey}`,
    marginBottom: spacing.normal,

    '& > div': {
      position: 'relative',
    },
  },
  overTitle: {
    textTransform: 'uppercase',
  },
  title: {
    textTransform: 'uppercase',
    marginBottom: spacing.normal,
  },
  categoryButtonsContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    overflowX: 'auto',

    paddingBottom: spacing.xSmall,

    '&::-webkit-scrollbar': {
      height: '3px',
    },

    '&::-webkit-scrollbar-track': {
      background: colors.light,
    },

    '& *': {
      whiteSpace: 'nowrap',
    },
  },
  searchFormContainer: {
    position: 'absolute',
    top: 0,
    right: 0,

    [queries.md]: {
      position: 'relative',
      marginBottom: spacing.small,
    },
  },
});
