import Link from 'next/link';
import React from 'react';
import { breadcrumbStyles } from './styles';

interface Props {
  links: {
    label: string;
    href?: string;
  }[]
}

export const Breadcrumb: React.FC<Props> = ({
  links,
}) => {
  const styles = breadcrumbStyles();

  return (
    <div className={styles.container}>
      {
        links.map((link, index) => (
          <div
            key={index}
            className={styles.link}
          >
            {link.href ? (
              <Link href={link.href}>
                {
                link.label
              }

              </Link>
            ) : (
              <span>{link.label}</span>
            )}
          </div>
        ))
      }
    </div>
  );
};
