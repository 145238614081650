import React, { useState } from 'react';
import Form, { Field } from 'rc-field-form';
import { subscribeNewsletterFormStyles } from './styles';
import { Button } from '../../Button';
import { createNewsletterSubscription } from '../../../graphql/mutation';

export const SubscribeNewsletterForm: React.FC = () => {
  const styles = subscribeNewsletterFormStyles();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = React.useState([]);
  const [alreadyExists, setAlreadyExists] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
  const onFinish = async (values: any) => {
    setLoading(true);
    setErrors([]);
    setSuccess(false);
    setAlreadyExists(false);

    const contactSubmission = await createNewsletterSubscription({
      email: values.email,
    });

    if (!contactSubmission) {
      setAlreadyExists(true);
      setLoading(false);
      return;
    }

    if (contactSubmission) {
      setSuccess(true);
    }

    setLoading(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFinishFailed = ({ errorFields }: any) => {
    setSuccess(false);
    setErrors(errorFields);
  };

  return (
    <div className={styles.container}>
      <Form
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <div className={styles.inputContainer}>
          <Field
            name="email"
            rules={[
              {
                required: true,
                message: 'Please input your email!',
              },
              {
                type: 'email',
                message: 'Please input a valid email!',
              },
            ]}
          >
            <input placeholder="Your email" />
          </Field>
          <Field>
            <Button
              loading={loading}
              htmlType="submit"
              disableAnimation
              className={styles.submitButton}
            >
              Submit
            </Button>
          </Field>
        </div>

        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
        {errors.map((error: any) => (
          <p
            className={styles.error}
            key={error.name}
          >
            {error.errors}
          </p>
        ))}
        {
          alreadyExists && (
            <p className={styles.error}>
              You are already subscribed to our newsletter!
            </p>
          )
        }
        {success && (
          <p className={styles.success}>
            You have successfully subscribed to our newsletter!
          </p>
        )}
      </Form>
    </div>
  );
};
