import React, { useEffect } from 'react';
import { Cell, Grid } from '@faceless-ui/css-grid';
import { Slide, SliderProvider, SliderTrack } from '@faceless-ui/slider';
import { useWindowInfo } from '@faceless-ui/window-info';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { BackgroundColorType, SpacingType } from '../../utilities/type';
import { Article } from '../../../payload-types';
import { articlesScrollerStyles } from './styles';
import { BackgroundColor, Spacing } from '../../components';
import { breakpoints } from '../../styles';
import { formatDate } from '../../utilities';

interface Props {
  articlesScroller: {
    backgroundColor?: BackgroundColorType;
    spacings: SpacingType
    title: string;
    articles: string[] | Article[];
  };
  id?: string;
  blockName?: string;
  blockType: 'articlesScroller';
}

export const ArticlesScroller: React.FC<Props> = ({
  articlesScroller,
}) => {
  const styles = articlesScrollerStyles();
  const windowInfo = useWindowInfo();
  const router = useRouter();

  useEffect(() => {
    // reset state
  }, [router.asPath]);

  // eslint-disable-next-line no-nested-ternary
  const slidesToShow = windowInfo.width > breakpoints.lg ? 2.5
    // eslint-disable-next-line no-nested-ternary
    : windowInfo.width > breakpoints.md ? 2
      // eslint-disable-next-line no-nested-ternary
      : windowInfo.width > 850 ? 3
        : windowInfo.width > breakpoints.sm ? 2
          : 1.2;


  return (
    <BackgroundColor backgroundColor={articlesScroller.backgroundColor}>
      <Spacing spacing={articlesScroller.spacings}>
        <div className={styles.container}>
          <Grid>
            {
              articlesScroller.title && (
                <Cell cols={3}>
                  <h2>{articlesScroller.title}</h2>
                </Cell>
              )
            }
            <Cell cols={articlesScroller.title ? 9 : 12}>
              <SliderProvider
                slidesToShow={slidesToShow}
              >
                <SliderTrack className={styles.sliderTrack}>
                  {
                    articlesScroller.articles.map((article, index) => (
                      <Slide
                        key={article.id}
                        index={index}
                      >
                        <Link
                          href={`/article/${article.slug}`}
                        >
                          <div
                            className={styles.card}
                          >
                            <div
                              className={styles.cardBackgroundContainer}
                              style={{
                                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${article.featuredImage.sizes.card.url || article.featuredImage.url})`,
                              }}
                            />
                            <div className={styles.cardInnerContainer}>
                              <h3>{article.title}</h3>
                              <span>{formatDate(article.date)}</span>
                            </div>
                          </div>
                        </Link>
                      </Slide>
                    ))
                  }
                </SliderTrack>
              </SliderProvider>
            </Cell>
          </Grid>
        </div>
      </Spacing>
    </BackgroundColor>
  );
};
