import { PaginatedDocs } from 'payload/dist/mongoose/types';
import { Article, Category } from '../../../../payload-types';
import { BUTTONS, COMPARE_CONTRAST_CARDS, CONTENT, CTA, IMAGE, IMAGE_CONTENT } from '../blocks';
import { MEDIA_FIELD, META_FIELDS } from '../fields';

const ARTICLES_QUERY = `
  query Articles($page: Int, $limit: Int, $sort: String, $where: Article_where) {
    Articles(page: $page, limit: $limit, sort: $sort, where: $where) {
      docs {
        author {
          firstName
          lastName
          profilePicture {
            ${MEDIA_FIELD}  
          }
        }
        categories {
          id
          title
          slug
          featuredColor {
            enableFeaturedColor
            color
          }
        }
        date
        description
        id
        meta {
          ${META_FIELDS}
        }
        slug
        title
        featuredImage {
          ${MEDIA_FIELD}
        }
        content {
          ${CONTENT}
        }
      }
      totalDocs
      offset
      limit
      totalPages
      page
      pagingCounter
      hasPrevPage
      hasNextPage
      prevPage
      nextPage
    }
    Categories(limit: 100) {
      docs {
        id
        slug
        title
        featuredColor {
          enableFeaturedColor
          color
        }
      }
    }
  }
`;

const ARTICLE_QUERY = `
  query Articles($slug: String) {
    Articles(where: { slug: { equals: $slug} }) {
      docs {
        author {
          firstName
          lastName
          profilePicture {
            ${MEDIA_FIELD}  
          }
        }
        categories {
          id
          title
          slug
          featuredColor {
            enableFeaturedColor
            color
          }
        }
        date
        description
        id
        meta {
          ${META_FIELDS}
        }
        slug
        title
        featuredImage {
          ${MEDIA_FIELD}
        }
        content {
          ${CONTENT}
          ${IMAGE}
          ${IMAGE_CONTENT}
          ${BUTTONS}
          ${CTA}
          ${COMPARE_CONTRAST_CARDS}
        }
      }
    }
  }
`;

interface Props {
  page?: number;
  limit?: number;
  category?: string;
  lookup?: string;
  dontInclude?: string;
}

export const fetchArticles = async ({
  page = 1,
  limit = 10,
  category = null,
  lookup = null,
  dontInclude = null,
}: Props): Promise<{
  articles: PaginatedDocs<Article>;
  categories: Category[]
}> => {
  const { data, errors } = await fetch(`${process.env.NEXT_PUBLIC_SERVER_URL}/api/graphql`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    next: {
      revalidate: 600,
    },
    body: JSON.stringify({
      query: ARTICLES_QUERY,
      variables: {
        page,
        limit,
        where: {
          ...(dontInclude && {
            id: {
              not_equals: dontInclude,
            },
          }),
          ...(category && {

            categories: {
              in: category,
            },
          }),
          ...(lookup && {
            OR: [
              {
                title: {
                  contains: lookup,
                },
              },
              {
                description: {
                  contains: lookup,
                },
              },
            ],
          }),
        },
      },
    }),
  }).then((res) => res.json());

  if (errors) {
    console.error(JSON.stringify(errors));
    throw new Error();
  }

  return {
    articles: data.Articles,
    categories: data.Categories.docs,
  };
};

export const fetchArticle = async (slug?: string): Promise<{article: Article | null}> => {
  const { data, errors } = await fetch(`${process.env.NEXT_PUBLIC_SERVER_URL}/api/graphql`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    next: {
      revalidate: 600,
    },
    body: JSON.stringify({
      query: ARTICLE_QUERY,
      variables: {
        slug,
      },
    }),
  }).then((res) => res.json());

  if (errors) {
    console.error(JSON.stringify(errors));
    throw new Error();
  }

  return {
    article: data.Articles.docs[0],
  };
};
