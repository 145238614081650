import React from 'react';
import Script from 'next/script';

export const GoogleAnalyticsTag: React.FC = () => {
  if (
    process.env.NODE_ENV !== 'production'
    || !process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS
  ) {
    return null;
  }

  return (
    <React.Fragment>
      <Script
        strategy="lazyOnload"
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
      />

      <Script strategy="lazyOnload">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
          page_path: window.location.pathname,
          });
        `}
      </Script>
    </React.Fragment>
  );
};
