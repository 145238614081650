import { createUseStyles } from 'react-jss';
import {
  base,
  body,
  colors,
  h2,
  headerHeight,
  mobileHeaderHeight,
  queries,
  spacing,
} from '../../../styles';

export const HomeHeaderStyles = createUseStyles({
  container: {
    height: `calc(100vh - ${headerHeight} - ${mobileHeaderHeight})`,
    backgroundColor: colors.dark,
    color: colors.white,
    paddingBottom: spacing.medium,

    [queries.md]: {
      height: '100%',
    },
  },
  gridContainer: {
    height: '100%',
  },
  headerContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  title: {
    textTransform: 'uppercase',
    color: colors.white,
    marginBottom: base(),

    '@media (max-width: 1600px)': {
      ...h2,
      color: colors.white,
      marginBottom: base(),
    },
  },
  description: {
    color: colors.white,
    marginBottom: base(2),
  },
  buttonContainer: {
    zIndex: 10,
    marginBottom: spacing.medium,
  },
  primaryButton: {
    marginRight: base(),
  },
  trustedByOuterContianer: {
    position: 'relative',

    '& button': {
      opacity: 0,
      visibility: 'hidden',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      transition: 'all 0.3s ease-in-out',
      width: '100%',
      height: '100%',

      '& *': {
        ...body,
      },
    },
    '&:hover button': {
      opacity: 1,
      visibility: 'visible',
    },
    '&:hover .invx-trustedby__container': {
      filter: 'blur(3px)',
      opacity: 0.5,
    },
    '& .invx-trustedby__container img': {
      filter: 'grayscale(1000%)',
      transition: 'all 0.3s ease-in-out',
    },
    '&:hover .invx-trustedby__container img': {
      filter: 'grayscale(0%) !important',
    },

    [queries.md]: {
      '& .invx-trustedby__container': {
        filter: 'blur(3px)',
        opacity: 0.5,
      },
      '& button': {
        opacity: 1,
        visibility: 'visible',
      },
    },
  },
  trustedByContainer: {
    '& img': {
      height: base(3),
      width: 'auto',
      marginRight: base(1.5),

      [queries.lg]: {
        height: base(2),
        marginRight: base(1),
      },

      [queries.md]: {
        height: base(2),
        marginRight: base(1),
      },
    },
    transition: 'all 0.3s ease-in-out',
  },
  rightContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
    [queries.md]: {
      minHeight: '400px',
    },
  },
  homeVideo: {
    width: '100%',
    height: '100%',

    // stop video from overflowing container
    position: 'absolute',
    top: 0,
    left: 0,
    // the video is squished
    objectFit: 'contain',
  },
  topLeftTags: {
    position: 'absolute',
    top: '25%',
    left: '25%',
    '& *': {
      color: colors.white,
    },

    [queries.md]: {
      left: '5%',
      top: '5%',
    },
  },
  bottomRightTags: {
    position: 'absolute',
    bottom: '15%',
    right: '20%',
    '& *': {
      color: colors.white,
    },

    [queries.md]: {
      right: '5%',
      bottom: '5%',
    },
  },
});
