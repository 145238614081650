import { createUseStyles } from 'react-jss';
import { base, htmlFontSize, spacing } from '../../styles';

export const tagStyles = createUseStyles({
  tag: {
    padding: `${base(0.25)} ${base(2)} ${base(0.25)} ${base()}`,
    borderRadius: base(0.5),


    background: 'rgba(139,145,152,.5)',
    webkitBackdropFilter: 'blur(10px)',
    backdropFilter: 'blur(10px)',
    marginBottom: spacing.small,

    display: 'flex',
    alignItems: 'center',

    '& svg': {
      width: htmlFontSize,
      marginRight: spacing.xSmall,
    },

    transform: 'scale(0)',
    transition: 'all 1s ease-in-out',
  },
  tagVisible: {
    transform: 'scale(1)',
  },
});
