import React from 'react';
import { buttonsStyles } from './styles';
import { BackgroundColorType, LinkType, SpacingType } from '../../utilities/type';
import { BackgroundColor, Button, Container, Spacing } from '../../components';
import { AppLink } from '../../components/AppLink';

interface Props {
  buttons: {
    backgroundColor?: BackgroundColorType,
    spacings: SpacingType,
    alignment?: 'left' | 'center' | 'right';
    buttons: {
      button: {
        type?: 'primary' | 'secondary';
        link: LinkType,
      };
      id?: string;
    }[];
  };
  id?: string;
  blockName?: string;
  blockType: 'buttons';
}

export const Buttons: React.FC<Props> = ({
  buttons,
}) => {
  const styles = buttonsStyles({ alignment: buttons.alignment });

  return (
    <BackgroundColor backgroundColor={buttons.backgroundColor}>
      <Spacing spacing={buttons.spacings}>
        <Container className={styles.container}>
          {
            buttons.buttons.map(({ button, id }) => (
              <AppLink
                key={id}
                link={button.link}
              >
                <Button type={button.type}>
                  {button.link.label}
                </Button>
              </AppLink>
            ))
          }
        </Container>
      </Spacing>
    </BackgroundColor>
  );
};
