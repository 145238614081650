import { createUseStyles } from 'react-jss';
import { base, body, colors, queries, spacing } from '../../styles';

export const faqStyles = createUseStyles({
  container: {

  },
  faqContainer: {
    borderBottom: `1px solid ${colors.dark}`,
    padding: `0 ${spacing.small}`,
    '& .invx__faq__collapsible__toggler': {
      textAlign: 'left',
      cursor: 'pointer',
      width: '100%',
      padding: `${spacing.small} 0`,
      border: 0,
      background: 'transparent',

      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',


      '& h3': {
        ...body,
        fontWeight: 600,
      },
    },

    [queries.sm]: {
      padding: `0 ${spacing.xSmall}`,
      '& .invx__faq__collapsible__toggler': {
        alignItems: 'flex-start',
      },
    },

    '& .invx-icon__open-modal': {
      display: 'none',
    },
    '& .invx__faq__collapsible__toggler--is-open': {
      '& .invx-icon__closed-modal': {
        display: 'none',
      },
      '& .invx-icon__open-modal': {
        display: 'block',
      },
    },
  },
  icon: {
    '& *': {
      minWidth: `${base(0.9)} !important`,
      height: `${base(0.9)} !important`,
    },
    marginRight: spacing.small,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: colors.dark,
  },
});
