import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import { Article } from '../../../payload-types';
import { truncateString } from '../../utilities';
import { ArticleMeta } from '../ArticleMeta';
import { articleCardStyles } from './styles';

interface Props {
  article: Article;
}

export const ArticleCard: React.FC<Props> = ({ article }) => {
  const styles = articleCardStyles();

  return (
    <Link
      href={`/article/${article.slug}`}
      className={styles.linkContainer}
    >
      <div className={styles.container}>
        {article.featuredImage && typeof article.featuredImage === 'object' && (
          <div className={styles.featuredImageContainer}>
            <Image
              src={article.featuredImage.sizes.card.url}
              alt={article.featuredImage.alt}
              width={article.featuredImage.sizes.card.width}
              height={article.featuredImage.sizes.card.height}
              quality={100}
            />
          </div>
        )}

        <div className={styles.categoryTags}>
          {article.categories.map((category) => (
            <span
              key={category.slug}
              className={styles.categoryTag}
            >
              {category.title}
            </span>
          ))}
        </div>
        <h3 className={styles.title}>{article.title}</h3>
        <span className={styles.spacer} />
        <p>{truncateString(article.description)}</p>
        <ArticleMeta article={article} />
      </div>
    </Link>

  );
};
