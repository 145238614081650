import Link from 'next/link';
import React from 'react';

interface Props {
  link: {
    type?: 'internal' | 'external';
    label: string;
    url: string;
    reference: {
      value: { slug: string };
      relationTo: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } | any
    noFollow?: boolean;
    noreferer?: boolean;
    newTab?: boolean;
  };
  children?: React.ReactNode;
  className?: string;
}

const prefix = {
  service: '/service',
  case_study: '/case-study',
  page: '',
  post: '',
};

export const AppLink: React.FC<Props> = ({
  link,
  children,
  className,
}) => {
  const { type, label, url, reference, noFollow, noreferer, newTab } = link;


  if (type === 'internal') {
    if (typeof reference !== 'object') return null;

    let pageSlug = reference?.value.slug as string;

    if (pageSlug === 'home') {
      pageSlug = '';
    }

    return (
      <Link
        href={`${prefix[reference.relationTo]}/${pageSlug}`}
        rel={`${noFollow ? 'nofollow' : ''} ${noreferer ? 'noreferrer' : ''}`}
        target={newTab ? '_blank' : '_self'}
        className={className}
      >
        {
          children || label
        }
      </Link>
    );
  }
  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a
      href={url}
      rel={`${noFollow ? 'nofollow' : ''} ${noreferer || newTab ? 'noreferrer' : ''}`}
      target={newTab ? '_blank' : '_self'}
      className={className}
    >
      {
        children || label
      }
    </a>
  );
};
