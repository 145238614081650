import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { headerCaseStudyCardStyles } from './styles';
import { CaseStudy } from '../../../payload-types';

interface Props {
  featuredCaseStudy: {
    enableCaseStudy?: boolean;
    caseStudy: string | CaseStudy;
  }
}

export const HeaderCaseStudyCard: React.FC<Props> = ({
  featuredCaseStudy,
}) => {
  const styles = headerCaseStudyCardStyles();

  if (!featuredCaseStudy.enableCaseStudy || typeof featuredCaseStudy.caseStudy === 'string') return null;

  return (
    <Link
      href={`/case-study/${featuredCaseStudy.caseStudy.slug}`}
      className={styles.linkContainer}
    >
      <button
        className={styles.container}
        type="button"
      >
        {
          typeof featuredCaseStudy.caseStudy.featuredImage === 'object' && (
            <div className={styles.imageContainer}>
              <Image
                className={styles.image}
                src={featuredCaseStudy.caseStudy.featuredImage.sizes.card.url || featuredCaseStudy.caseStudy.featuredImage.url}
                width={featuredCaseStudy.caseStudy.featuredImage.sizes.card.width || featuredCaseStudy.caseStudy.featuredImage.width}
                height={featuredCaseStudy.caseStudy.featuredImage.sizes.card.height || featuredCaseStudy.caseStudy.featuredImage.height}
                alt={featuredCaseStudy.caseStudy.featuredImage.alt}
              />
            </div>
          )
        }
        <div className={styles.contentContainer}>
          <h3 className={styles.title}>Latest Case Study</h3>
          <p className={styles.description}>{featuredCaseStudy.caseStudy.title}</p>
        </div>
      </button>
    </Link>
  );
};
