import { Page } from '../../../../payload-types';
import { ARTICLES_SCROLLER, BUTTONS, CARD_SCROLLER, CONTENT, CTA, FAQ, IMAGE, IMAGE_CONTENT, FADE_SCROLL_SECTIONS, SERVICE_SCROLLER, TESTIMONIALS } from '../blocks';
import { LINK_FIELD, MEDIA_FIELD, META_FIELDS } from '../fields';

const PAGE_QUERY = `
  query Pages($slug: String) {
    Pages(where: { slug: { equals: $slug} }) {
      docs {
        id
        slug
        title
        overTitle
        description
        header {
          type
          trustedByLogos {
            id
            logo {
              ${MEDIA_FIELD}
            }
          }
          buttons {
            id
            button {
              type
              icon
              link {
                ${LINK_FIELD}
              }
            }
          }
          featuredImage {
            ${MEDIA_FIELD}
          }
        }
        layout {
          ${CONTENT}
          ${IMAGE}
          ${IMAGE_CONTENT}
          ${BUTTONS}
          ${CTA}
          ${FAQ}
          ${CARD_SCROLLER}
          ${TESTIMONIALS}
          ${SERVICE_SCROLLER}
          ${ARTICLES_SCROLLER}
          ${FADE_SCROLL_SECTIONS}
        }
        meta {
          ${META_FIELDS}
        }
      }
    }
  }
`;


export const fetchPage = async (slug?: string): Promise<{page: Page | null}> => {
  const { data, errors } = await fetch(`${process.env.NEXT_PUBLIC_SERVER_URL}/api/graphql`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    next: {
      revalidate: 600,
    },
    body: JSON.stringify({
      query: PAGE_QUERY,
      variables: {
        slug,
      },
    }),
  }).then((res) => res.json());

  if (errors) {
    console.error(JSON.stringify(errors));
    throw new Error();
  }

  return {
    page: data.Pages.docs[0],
  };
};
