import React from 'react';
import { colors } from '../../../styles';
import { fullLockupStyles } from './styles';

interface Props {
  className?: string;
}

export const FullLockup: React.FC<Props> = ({ className }) => {
  const styles = fullLockupStyles();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1920 459"
      className={`${styles.fullLockup} ${className}`}
    >
      <defs>
        <clipPath id="b">
          <path
            style={{
              fill: 'none',
            }}
            d="M41.95 36.76h1836.1v385.47H41.95z"
          />
        </clipPath>
        <style>{`.invx__full-lockup-logo{fill:${colors.dark}}`}</style>
      </defs>
      <path
        className="invx__full-lockup-logo"
        d="M41.95 102.5h45.86v319.73H41.95zM118.89 422.23h43.3V223.76l161.17 198.47h42.01V107.58h-43.3v237.5L118.89 102.01v320.22zM396.43 422.23h43.31V223.76l161.17 198.47h42.01V107.58h-43.3v237.5L396.43 102.01v320.22zM1591.93 103.07l-54.45 110.01-54.01-110.01h-48.86v.44l78.87 158.71-78.87 159.58v.44h48.43l54.44-110.45 54.87 110.45h48.44v-.44l-78.87-159.58 78.87-158.71v-.44h-48.86z"
      />
      <g
        style={{
          clipPath: 'url(#b)',
        }}
      >
        <path
          className="invx__full-lockup-logo"
          d="M1007.87 266.84a555.559 555.559 0 0 0-15.99-28.39c.14 2.52.2 5.05.2 7.6 0 78.26-63.67 141.94-141.93 141.94s-141.93-63.68-141.93-141.94 63.67-141.94 141.93-141.94c31.7 0 61 10.44 84.66 28.08h49.8c-3.14-3.69-6.43-7.27-9.89-10.72-16.17-16.17-35.01-28.88-55.99-37.75-21.73-9.19-44.81-13.85-68.58-13.85s-46.85 4.66-68.58 13.85c-20.98 8.87-39.81 21.58-55.99 37.75-16.17 16.18-28.87 35.01-37.74 56-9.19 21.73-13.85 44.81-13.85 68.58s4.66 46.85 13.85 68.58c8.87 20.98 21.57 39.82 37.74 56 16.18 16.17 35.01 28.87 55.99 37.74 21.73 9.19 44.81 13.85 68.58 13.85s46.85-4.66 68.58-13.85c20.98-8.87 39.82-21.57 55.99-37.74 16.18-16.18 28.88-35.02 37.75-56 3.13-7.39 5.73-14.94 7.8-22.61a531.52 531.52 0 0 0-12.4-25.18"
        />
        <path
          className="invx__full-lockup-logo"
          d="m1326.6 118.85-22.22 27.81c-40.71 50.92-77.9 105.29-110.53 161.58-21.37 36.84-41.15 75.16-58.87 114h-37.09a969.513 969.513 0 0 0-47.58-116.46c-25.72-52.85-56.51-103.67-91.51-151.08l.79-.58h40.98c30.4 43.34 57.43 89.21 80.53 136.66a997.242 997.242 0 0 1 37.19 86.57c14.28-29.33 29.67-58.23 45.94-86.3 27.61-47.62 58.4-93.9 91.77-137.96h-151.93l-4.33-10.67c-4.33-10.61-9.3-21.07-14.8-31.1-10.15-18.53-22.36-36.07-36.28-52.16l25.9-22.41c15.49 17.91 29.09 37.46 40.42 58.1 4.27 7.8 8.26 15.83 11.92 23.99h199.73Z"
        />
      </g>
      <path
        className="invx__full-lockup-logo"
        d="M1357.67 102.5h45.87v319.73h-45.87zM1829.17 103.07l-54.43 110.01-54.01-110.01h-48.86v.44l78.86 158.71-78.86 159.58v.44h48.43l54.44-110.45 54.87 110.45h48.44v-.44l-78.88-159.58 78.88-158.71v-.44h-48.88z"
      />
    </svg>
  );
};
