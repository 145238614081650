import React from 'react';
import Script from 'next/script';

export const ClarityTag: React.FC = () => {
  if (
    process.env.NODE_ENV !== 'production'
    || !process.env.NEXT_PUBLIC_CLARITY_TAG_ID
  ) {
    return null;
  }

  return (
    <React.Fragment>
      <Script strategy="lazyOnload">
        {`
          (function(c,l,a,r,i,t,y){
              c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
              t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
              y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
          })(window, document, "clarity", "script", "${process.env.NEXT_PUBLIC_CLARITY_TAG_ID}");
        `}
      </Script>
    </React.Fragment>
  );
};
