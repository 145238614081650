import React from 'react';
import { useWindowInfo } from '@faceless-ui/window-info';
import { Container } from '../../components';
import { Service } from '../../../payload-types';
import { serviceScrollerStyles } from './styles';
import { ServiceStickyContent } from './components';

interface Props {
  services: Service[];
  id?: string;
  blockName?: string;
  blockType: 'serviceScroller';
}

export const ServiceScroller: React.FC<Props> = ({
  services,
}) => {
  const styles = serviceScrollerStyles();

  const {
    breakpoints: { m },
  } = useWindowInfo();


  return (
    <Container className={styles.container}>
      <div className={styles.serviceStickyContianer}>
        {
          services.map((item, index) => typeof item === 'object' && (
            <ServiceStickyContent
              key={index}
              service={item}
              midBreak={m}
            />
          ))
        }
      </div>
    </Container>
  );
};
