import { Cell, Grid } from '@faceless-ui/css-grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as icons from '@fortawesome/free-brands-svg-icons';
import React from 'react';
import Link from 'next/link';
import { InnovixxLogo } from '../../InnovixxLogo';
import { Container } from '../Container';
import { footerStyles } from './styles';
import { SubscribeNewsletterForm } from '../../forms';
import { Footer as FooterType, SocialMedia } from '../../../../payload-types';
import { AppLink } from '../../AppLink';

interface Props {
  footer: FooterType
  socialMedia: SocialMedia
}

export const Footer: React.FC<Props> = ({
  footer,
  socialMedia,
}) => {
  const styles = footerStyles();

  return (
    <div className={styles.container}>
      <Container
        containerSize="large"
      >
        <Grid>
          <Cell cols={2}>
            <Link
              href="/"
              title="Innovixx Home"
            >
              <InnovixxLogo
                light
                className={styles.logo}
              />
            </Link>
          </Cell>
          <Cell
            cols={6}
            colsM={6}
            colsL={8}
          >
            <div className={styles.footerLinksContainer}>
              <ul>
                {
                  (footer && footer.links) && footer.links.map((link) => (
                    <li key={link.id}>
                      <AppLink link={link.link} />
                    </li>
                  ))
                }
              </ul>
            </div>
            <div className={styles.listLinksContainer}>
              {
                (footer && footer.menuLists) && footer.menuLists.map((menuList) => (
                  <div
                    className={styles.listLinks}
                    key={menuList.id}
                  >
                    <h4>{menuList.title}</h4>
                    <ul>
                      {
                        menuList.links.map((link) => (
                          <li key={link.id}>
                            {
                              link.link.url !== 'NO_LINK' ? (
                                <AppLink link={link.link} />
                              ) : (
                                <span>{link.link.label}</span>
                              )
                            }
                          </li>
                        ))
                      }
                    </ul>
                  </div>
                ))
              }
            </div>
          </Cell>
          <Cell
            cols={4}
            colsL={12}
          >
            <div className={styles.newsletterForm}>
              <h3>Subscribe to our newsletter</h3>
              <SubscribeNewsletterForm />
            </div>
          </Cell>
        </Grid>
        <div className={styles.socialLinksContainer}>
          {
            (socialMedia && socialMedia.socialMedia) && socialMedia.socialMedia.map((item) => (
              <AppLink
                link={item.link}
                key={item.id}
              >
                <FontAwesomeIcon icon={icons[item.icon]} />
              </AppLink>
            ))
          }
        </div>
        <div className={styles.bottomBarContainer}>
          <span>
            Copyright ©
            {' '}
            {new Date().getFullYear()}
            {' '}
            Innovixx. All rights
            reserved.
          </span>
          <div className={styles.bottomBarLinks}>
            {
              (footer && footer.bottomBar && footer.bottomBar.links) && footer.bottomBar.links.map((link) => (
                <AppLink
                  link={link.link}
                  key={link.id}
                />
              ))
            }
          </div>
        </div>
      </Container>
    </div>
  );
};
