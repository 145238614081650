import { Cell, Grid } from '@faceless-ui/css-grid';
import Link from 'next/link';
import Form, { Field, useForm } from 'rc-field-form';
import React, { useState } from 'react';
import { createContactSubmission } from '../../../graphql/mutation';
import { Button } from '../../Button';
import { contactFormStyles } from './styles';
import { Icon } from '../../RichText/elements';

const platforms = ['website', 'marketing', 'design', 'other'];
const timelines = ['Less Than 4 Weeks', '4-8 Weeks', '8+ Weeks'];

export const ContactForm: React.FC = () => {
  const styles = contactFormStyles();
  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = async (values: any) => {
    setError(false);
    setSuccess(false);

    const contactSubmission = await createContactSubmission({
      email: values.email,
      name: values.name,
      message: values.message,
      phone: values.phone,
      timeline: values.timeline,
    });

    if (!contactSubmission) {
      setError(true);
      return;
    }

    if (contactSubmission) {
      setSuccess(true);
    }
  };

  const [form] = useForm();

  return (
    <div className={styles.container}>
      {
        !success ? (
          <Form
            onFinish={onSubmit}
            form={form}
            initialValues={{
              ...platforms.reduce((acc, platform) => ({
                ...acc,
                [`platform-${platform}`]: false,
              }), {}),
              timeline: '4-8 Weeks',
            }}
          >
            <p className={styles.fieldLabel}>What do you need help with?</p>
            <div className={styles.selectContainer}>
              {
                platforms.map((platform) => (
                  <Field
                    name={`platform-${platform}`}
                    key={platform}
                  >
                    {({ value, onChange }) => (
                      <Button
                        type={value ? 'primary' : 'secondary'}
                        onClick={() => {
                          onChange(!value);
                        }}
                        disableAnimation
                      >
                        {platform.charAt(0).toUpperCase() + platform.slice(1)}
                      </Button>
                    )}

                  </Field>
                ))
              }
            </div>
            <p className={styles.fieldLabel}>When do you need this project?</p>
            <Field
              name="timeline"
            >
              {({ value, onChange }) => (
                <div className={styles.selectContainer}>
                  {
                    timelines.map((timeline) => (
                      <Button
                        key={timeline}
                        type={value === timeline ? 'primary' : 'secondary'}
                        onClick={() => {
                          onChange(timeline);
                        }}
                        disableAnimation
                      >
                        {timeline}
                      </Button>
                    ))
                  }
                </div>
              )}
            </Field>
            <Field name="name">
              <input
                placeholder="Full Name"
                className={styles.input}
              />
            </Field>
            <Grid className={styles.row}>
              <Cell
                cols={3}
                colsM={4}
              >
                <Field name="email">
                  <input
                    placeholder="Email"
                    className={styles.input}
                  />
                </Field>
              </Cell>
              <Cell
                cols={3}
                colsM={4}
              >
                <Field name="phone">
                  <input
                    placeholder="Phone"
                    className={styles.input}
                  />
                </Field>
              </Cell>
            </Grid>
            <Field name="message">
              <textarea
                placeholder="Please leave us a message with a brief description of what you're looking for e.g. I would like a website redesign"
                className={styles.input}
                rows={5}
              />
            </Field>
            <p
              className={styles.privacyPolicyText}
            >
              By submitting you are agreeing to our
              {' '}
              <Link href="/privacy-policy">Privacy Policy</Link>
            </p>
            <Field>
              <div className={styles.submitContainer}>
                <Button htmlType="submit">Get In Touch</Button>
              </div>
            </Field>
            {
              error && (
                <p className={styles.error}>Something went wrong, please try again later</p>
              )
            }
          </Form>
        ) : (
          <div className={styles.successContainer}>
            <Icon
              className={styles.successIcon}
              size="normal"
              icon="faCheckCircle"
            />
            <h3>Message sent</h3>
            <p className={styles.success}>We have received your request and will be in contact shortly</p>
          </div>
        )
      }
    </div>
  );
};
