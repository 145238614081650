import { createUseStyles } from 'react-jss';
import { base, colors, h5, queries, spacing } from '../../styles';

export const shareModalStyles = createUseStyles({
  container: {
    marginTop: '20vh',
    border: 0,
    padding: 0,
    maxWidth: 600,
    width: '100%',
    boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.5)',

    [queries.sm]: {
      position: 'relative',
      marginRight: base(),
      marginLeft: base(),
    },

  },
  headerContainer: {
    display: 'flex',
    background: colors.dark,
    color: colors.white,
    padding: `${spacing.xSmall} ${spacing.small}`,
    justifyContent: 'space-between',
  },
  title: {
    ...h5,
    color: colors.white,
  },
  bodyContainer: {
    padding: spacing.small,
  },
  socialIconsContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: spacing.xSmall,
  },
  socialIconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    width: base(2),
    height: base(2),
    background: colors.dark,
    color: colors.white,

    margin: `0 ${base(0.25)}`,
  },
  copyLinkContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  input: {
    marginTop: spacing.xSmall,
    background: 'transparent',
    padding: `${base(0.2)} ${spacing.small}`,
    border: `1px solid ${colors.dark}`,
    outline: 'none',
    fontSize: base(),
    width: '80%',
    textAlign: 'center',
  },
  linkCopiedText: {
    color: colors.success,
  },
});
