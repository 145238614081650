import { Service } from '../../../../payload-types';
import { BUTTONS, CARD_SCROLLER, CONTENT, CTA, FADE_SCROLL_SECTIONS, FAQ, IMAGE, IMAGE_CONTENT, TESTIMONIALS } from '../blocks';
import { LINK_FIELD, META_FIELDS } from '../fields';

const SERVICE_QUERY = `
  query Services($slug: String) {
    Services(where: { slug: { equals: $slug} }) {
      docs {
        id
        slug
        title
        overTitle
        description
        buttons {
          id
          button {
            type
            link {
              ${LINK_FIELD}
            }
          }
        }
        categories {
          id
          title
          slug
          featuredColor {
            enableFeaturedColor
            color
          }
        }
        layout {
          ${CONTENT}
          ${IMAGE}
          ${IMAGE_CONTENT}
          ${FAQ}
          ${BUTTONS}
          ${CTA}
          ${CARD_SCROLLER}
          ${TESTIMONIALS}
          ${FADE_SCROLL_SECTIONS}
        }
        meta {
          ${META_FIELDS}
        }
      }
    }
  }
`;


export const fetchService = async (slug?: string): Promise<{service: Service | null}> => {
  const { data, errors } = await fetch(`${process.env.NEXT_PUBLIC_SERVER_URL}/api/graphql`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    next: {
      revalidate: 600,
    },
    body: JSON.stringify({
      query: SERVICE_QUERY,
      variables: {
        slug,
      },
    }),
  }).then((res) => res.json());

  if (errors) {
    console.error(JSON.stringify(errors));
    throw new Error();
  }

  return {
    service: data.Services.docs[0],
  };
};
