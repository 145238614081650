import { createUseStyles } from 'react-jss';
import { base, colors, h3, spacing } from '../../styles';

export const featuredArticleCardStyles = createUseStyles({
  linkContainer: {
    textDecoration: 'none !important',
  },
  container: {},
  imageContainer: {
    '& img': {
      width: '100%',
      height: 'auto',
    },
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  categoryTags: {
    marginBottom: spacing.xSmall,
    display: 'flex',
  },
  categoryTag: {
    padding: `0 ${base(1)}`,
    height: base(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.light,
    marginRight: spacing.small,
  },
  articleTitle: {
    ...h3,
  },
});
