import { createUseStyles } from 'react-jss';
import { SpacingType } from '../../../utilities/type';
import { queries, spacing as spacingStyle } from '../../../styles';

interface Props {
  spacing: SpacingType;
}

export const spacingStyles = createUseStyles({
  container: ({ spacing }: Props) => ({
    paddingTop: spacingStyle[spacing.top],
    paddingBottom: spacingStyle[spacing.bottom],

    [queries.md]: {
      paddingTop: spacingStyle.small,
    },
  }),
});
