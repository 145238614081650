import { base } from '../base';
import { colors } from '../colors';
import { queries } from '../queries';
import { spacing } from '../sizes';

export const defaultType = {
  fontFamily: 'Poppins, Open Sans, system-ui, sans-serif',
  color: colors.dark,
};

const heading = {
  ...defaultType,
  fontWeight: 600,
  margin: 0,

  '& span': {
    color: 'inherit',
    fontSize: 'inherit',
    fontWeight: 'inherit',
    lineHeight: 'inherit',
    margin: 'inherit',
    fontFamily: 'inherit',
  },
};

export const h1 = {
  ...heading,
  fontSize: base(4.522),
  lineHeight: base(4.522 * 1.25),

  [queries.lg]: {
    fontSize: base(3.157),
    lineHeight: base(3.157 * 1.25),
  },

  [queries.md]: {
    fontSize: base(2.4815),
    lineHeight: base(2.4815 * 1.25),
  },

  [queries.sm]: {
    fontSize: base(1.4415),
    lineHeight: base(1.4415 * 1.25),
  },
};

export const h2 = {
  ...heading,
  fontSize: base(2.198),
  lineHeight: base(2.198 * 1.25),
  marginBottom: spacing.small,

  [queries.md]: {
    fontSize: base(1.953),
    lineHeight: base(1.953 * 1.25),
  },

  [queries.sm]: {
    fontSize: base(1.2815),
    lineHeight: base(1.2815 * 1.25),
  },
};

export const h3 = {
  ...heading,
  fontSize: base(1.5995),
  lineHeight: base(1.5995 * 1.25),


  [queries.md]: {
    fontSize: base(1.25),
    lineHeight: base(1.25 * 1.25),
  },

  [queries.sm]: {
    fontSize: base(1.0125),
    lineHeight: base(1.0125 * 1.25),
  },
};

export const h4 = {
  ...heading,
  fontSize: base(1.25),
  lineHeight: base(1.25 * 1.25),
  fontWeight: 400,

  [queries.md]: {
    fontSize: base(1.25),
    lineHeight: base(1.25 * 1.25),
  },

  [queries.sm]: {
    fontSize: base(1),
    lineHeight: base(1 * 1.25),
  },
};

export const h5 = {
  ...heading,
  fontSize: base(1.131),
  lineHeight: base(1.131 * 1.25),
  fontWeight: 300,

  [queries.md]: {
    fontSize: base(1),
    lineHeight: base(1 * 1.25),
  },

  [queries.sm]: {
    fontSize: base(0.9),
    lineHeight: base(0.9 * 1.25),
  },
};

export const body = {
  ...defaultType,
  fontSize: base(0.8),
  lineHeight: base(0.8 * 1.5),
  fontWeight: 'inherit',

  [queries.md]: {
    lineHeight: base(0.8 * 1.25),
  },
};
