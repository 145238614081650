import { Cell, Grid } from '@faceless-ui/css-grid';
import React from 'react';
import { BackgroundColor, Button, Container, RichText, Spacing } from '../../components';
import { AppLink } from '../../components/AppLink';
import { BackgroundColorType, LinkType, SpacingType } from '../../utilities/type';
import { ctaStyles } from './styles';

interface Props {
  cta: {
    backgroundColor?: BackgroundColorType
    spacings: SpacingType
    title: string;
    description?: {
      [k: string]: unknown;
    }[];
    buttons: {
      button: {
        type?: 'primary' | 'secondary';
        link: LinkType
      };
      id?: string;
    }[];
  };
  id?: string;
  blockName?: string;
  blockType: 'cta';
}

export const Cta: React.FC<Props> = ({
  cta,
}) => {
  const styles = ctaStyles();

  return (
    <BackgroundColor backgroundColor={cta.backgroundColor}>
      <Spacing spacing={cta.spacings}>
        <Container className={styles.container}>
          <Grid>
            <Cell cols={6}>
              <h2 className={styles.title}>{cta.title}</h2>
            </Cell>
            <Cell cols={6}>
              <RichText content={cta.description} />
              <div className={styles.buttonsContainer}>
                {
                  cta.buttons.map(({ button, id }) => (
                    <AppLink
                      link={button.link}
                      key={id}
                    >
                      <Button type={button.type}>
                        {button.link.label}
                      </Button>
                    </AppLink>
                  ))
                }
              </div>
            </Cell>
          </Grid>
        </Container>
      </Spacing>
    </BackgroundColor>
  );
};
