import { createUseStyles } from 'react-jss';
import { base, containerSize, queries, spacing } from '../../styles';

export const fadeScrollSectionsStyles = createUseStyles({
  container: {

    maxWidth: `calc((100vw - ${containerSize.large}) / 2 + ${containerSize.large})`,
    width: 'auto',
    marginLeft: 'auto',
    paddingLeft: spacing.normal,

    [queries.md]: {
      paddingLeft: spacing.small,
    },

    position: 'relative',

    paddingBottom: base(5),
    paddingTop: base(5),
  },
});
