import { faCircle, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Image from 'next/image';
import React from 'react';
import { Article } from '../../../payload-types';
import { calculateContentReadTime, formatDate } from '../../utilities';
import { articleMetaStyles } from './styles';

interface Props {
  article: Article;
}

export const ArticleMeta: React.FC<Props> = ({ article }) => {
  const styles = articleMetaStyles();

  if (typeof article.author !== 'object') return null;

  return (
    <div className={styles.container}>
      {article.author.profilePicture && typeof article.author.profilePicture === 'object' ? (
        <div className={styles.profilePictureContainer}>
          <Image
            src={article.author.profilePicture.sizes.thumbnail.url || article.author.profilePicture.url}
            alt={article.author.profilePicture.alt}
            width={article.author.profilePicture.sizes.thumbnail.width || article.author.profilePicture.width}
            height={article.author.profilePicture.sizes.thumbnail.height || article.author.profilePicture.height}
            quality={100}
          />
        </div>
      ) : (
        <div className={styles.profilePictureContainer}>
          <FontAwesomeIcon icon={faUser} />
        </div>
      )}
      <div className={styles.infoContainer}>
        <span>
          {article.author.firstName}
          {' '}
          {article.author.lastName}
        </span>
        <span>
          <b>
            {formatDate(article.date)}
            {' '}
            <FontAwesomeIcon icon={faCircle} />
            {' '}
            {calculateContentReadTime(article.content)}
            {' '}
            Min Read
          </b>
        </span>
      </div>
    </div>
  );
};
