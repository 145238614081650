import { createUseStyles } from 'react-jss';

export const largeTextStyles = createUseStyles({
  largeText: {
    '& *': {
      fontSize: '1.35em',
      lineHeight: '1.35em',
    },
  },
});
