import { createUseStyles } from 'react-jss';
import { queries, spacing } from '../../styles';

interface Props {
  alignment: 'left' | 'center' | 'right';
}

export const buttonsStyles = createUseStyles({
  container: ({ alignment }: Props) => ({
    textAlign: alignment,

    '& button': {
      marginRight: spacing.small,
      marginBottom: spacing.small,
    },

    [queries.md]: {
      '& > *': {
        marginRight: 0,
      },
    },
  }),
});
