export const colors = {
  black: '#000',
  white: '#fff',
  light: '#f3f4f5',
  dark: '#1f1f1f',
  grey: '#3d3d3d',
  lightGrey: '#8b9198',
  purple: '#4f42fa',
  blue: '#00abf7',
  aqua: '#47e5bc',
  red: '#cf1322',

  success: '#2ab06f',
};

export type Colors = keyof typeof colors;
