import { Modal, useModal } from '@faceless-ui/modal';
import { faFacebookF, faLinkedinIn, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { Button } from '../Button';
import { shareModalStyles } from './styles';

interface Props {
  slug: string;
  title?: string;
  description?: string;
}

export const ShareModal: React.FC<Props> = ({
  slug,
  title,
  description,
}) => {
  const styles = shareModalStyles();
  const [copied, setCopied] = useState(false);
  const { toggleModal } = useModal();

  const { asPath } = useRouter();
  const origin = typeof window !== 'undefined' && window.location.origin
    ? window.location.origin
    : '';
  const url = `${origin}${asPath}`;

  return (
    <Modal
      slug={slug}
      className={styles.container}
      classPrefix="modal"
    >
      <div className={styles.headerContainer}>
        <span className={styles.title}>Share This Article</span>
        <Button
          type="link"
          onClick={() => {
            toggleModal(slug);
          }}
        >
          <FontAwesomeIcon
            icon={faTimes}
            color="white"
            size="lg"
          />
        </Button>
      </div>
      <div className={styles.bodyContainer}>
        <div className={styles.socialIconsContainer}>
          <a
            href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
            rel="noreferrer nooperner"
          >
            <span className={styles.socialIconContainer}>
              <FontAwesomeIcon icon={faFacebookF} />
            </span>
          </a>
          <a
            href={`https://www.linkedin.com/shareArticle?mini=true&url=${url}`}
            rel="noreferrer nooperner"
          >
            <span className={styles.socialIconContainer}>
              <FontAwesomeIcon icon={faLinkedinIn} />
            </span>
          </a>
          <a
            href={`https://twitter.com/intent/tweet?text=${title}&url=${url}`}
            rel="noreferrer nooperner"
          >
            <span className={styles.socialIconContainer}>
              <FontAwesomeIcon icon={faTwitter} />
            </span>
          </a>
          <a
            href={`mailto:?subject=${title}&body=${description} ${url}`}
            rel="noreferrer nooperner"
          >
            <span className={styles.socialIconContainer}>
              <FontAwesomeIcon icon={faEnvelope} />
            </span>
          </a>
        </div>
        <div className={styles.copyLinkContainer}>
          <span>Or copy the below link</span>
          <input
            value={url}
            className={styles.input}
            onClick={(e) => {
              e.currentTarget.select();
              navigator.clipboard.writeText(e.currentTarget.value);
              setCopied(true);
            }}
            readOnly
          />
          {
            copied && (
              <span className={styles.linkCopiedText}>
                Link Copied!
              </span>
            )
          }
        </div>
      </div>
    </Modal>
  );
};
