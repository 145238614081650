import { createUseStyles } from 'react-jss';
import { base, body, colors, spacing } from '../../../styles';

export const searchFormStyles = createUseStyles({
  container: {},
  inputContainer: {
    backgroundColor: colors.light,
    paddingLeft: spacing.small,
    '& input': {
      height: base(2),
      border: 0,
      backgroundColor: 'transparent',
      marginLeft: spacing.small,

      ...body,
      color: colors.grey,
      fontWeight: 500,

      '&::placeholder': {
        ...body,
        color: colors.lightGrey,
      },

      '&:focus': {
        outline: 'none',
      },
    },

    '&:focus-within': {
      '& > svg': {
        color: colors.grey,
      },
    },
  },
  searchIcon: {
    color: colors.lightGrey,
  },
});
