export const MEDIA_FIELD = `
  alt
  id
  url
  width
  height
  sizes {
    thumbnail {
      height
      url
      width
    }
    square {
      height
      url
      width
    }
    card {
      height
      url
      width
    }
    feature {
      url
      width
      height
    }
    largeFeature {
      url
      width
      height
    }
  }
`;
