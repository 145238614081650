import React, { useEffect, useState } from 'react';
import type { AppContext } from 'next/app';
import App from 'next/app';
import NextHead from 'next/head';
import { globalStyles } from '../styles/globals';
import {
  CookiesBanner,
  FacelessContainerWithProviders,
  Footer,
  GoogleAnalyticsTag,
  ClarityTag,
  Header,
  AtlassianWidget,
} from '../components';
import { fetchGlobals } from '../graphql/query';
import { Footer as FooterType, Header as HeaderType, SocialMedia } from '../../payload-types';


type AppProps = {
  pageProps: Record<string, unknown>;
  Component?: React.ComponentType;
  header: HeaderType;
  footer: FooterType
  socialMedia: SocialMedia
}

const MyApp = ({ Component, pageProps, header, footer, socialMedia }: AppProps): React.ReactElement => {
  const styles = globalStyles();

  const [loaded, setLoaded] = useState(false);


  useEffect(() => {
    setLoaded(true);
  }, []);

  // Remove react-jss false warning
  if (typeof window === 'undefined') {
    const originalWarn = console.warn;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    console.warn = (...args: any) => {
      if (
        args[0]
        !== 'Warning: [JSS] Rule is not linked. Missing sheet option "link: true".'
      ) {
        originalWarn(...args);
      }
    };
  }

  return (
    <React.Fragment>
      <GoogleAnalyticsTag />
      <ClarityTag />
      <NextHead>
        <link
          as="style"
          rel="stylesheet preload prefetch"
          href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&family=Poppins:wght@300;400;600&display=swap"
          type="text/css"
          crossOrigin="anonymous"
        />
      </NextHead>
      <FacelessContainerWithProviders>
        <div className={`${styles.app} ${loaded ? styles.appLoaded : ''}`}>
          <Header header={header} />
          <main className={styles.container}>
            <Component {...pageProps} />
          </main>
          <Footer
            footer={footer}
            socialMedia={socialMedia}
          />
        </div>
        <CookiesBanner />
      </FacelessContainerWithProviders>
      <AtlassianWidget />
    </React.Fragment>

  );
};

MyApp.getInitialProps = async (ctx: AppContext): Promise<AppProps> => {
  const appProps = await App.getInitialProps(ctx);

  const { header, footer, socialMedia } = await fetchGlobals();

  return {
    ...appProps,
    header,
    footer,
    socialMedia,
  };
};

export default MyApp;
