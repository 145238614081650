import { createUseStyles } from 'react-jss';
import { base, colors, queries, spacing } from '../../styles';

export const cookiesBannerStyles = createUseStyles({
  container: {
    position: 'fixed',
    bottom: base(),
    left: 0,
    right: 0,

    maxWidth: '1000px',
    margin: '0 auto',

    boxShadow: '0 0px 10px rgba(0, 0, 0, 0.2)',

    backgroundColor: colors.white,

    display: 'flex',
    alignItems: 'center',

    minHeight: '40px',

    padding: `0 0 0 ${base(2)}`,

    justifyContent: 'space-between',

    [queries.md]: {
      textAlign: 'center',

      padding: `${base(0.5)} ${base()}`,

      bottom: 0,

      flexDirection: 'column',
    },

    zIndex: 1000,
  },
  text: {
    margin: 0,
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    height: '100% !important',

    [queries.md]: {
      whiteSpace: 'nowrap',

      marginTop: spacing.small,
    },
    marginLeft: base(0.5),
  },
});
