import React from 'react';
import { Page } from '../../../../payload-types';
import { Container } from '../Container';
import { defaultPageHeaderStyles } from './styles';

interface Props {
  page: Page
}

export const DefaultPageHeader: React.FC<Props> = ({
  page,
}) => {
  const styles = defaultPageHeaderStyles();

  return (
    <Container className={styles.container}>
      <h1 className={styles.title}>{page.title}</h1>
      <p>{page.description}</p>
    </Container>
  );
};
