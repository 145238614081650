import { createUseStyles } from 'react-jss';
import { htmlFontSize } from '../base';
import { colors } from '../colors';
import { spacing } from '../sizes';
import { body, h1, h2, h3, h4, h5 } from '../types';

export const globalStyles = createUseStyles({
  '@global': {
    'html, body, #__next': {
      margin: 0,
      padding: 0,
      '-webkit-font-smoothing': 'antialiased',
      scrollBehavior: 'smooth',
      height: '100%',
    },
    '::-webkit-scrollbar': {
      background: '#3d3d3d',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#636363',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#6e6e6e',
    },
    h1,
    h2,
    h3,
    h4,
    h5,
    div: {
      fontWeight: 300,
    },
    p: {
      ...body,
      margin: `0 0 ${spacing.small} 0`,
      fontWeight: 300,
    },
    span: {
      ...body,
    },
    a: {
      ...body,
      color: colors.blue,
      cursor: 'pointer',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    ol: {
      padding: `0 0 0 ${spacing.small}`,
      margin: `0 0 ${spacing.small} 0`,
    },
    ul: {
      padding: `0 0 0 ${spacing.small}`,
      margin: `0 0 ${spacing.small} 0`,

      listStyleType: 'square',
    },
    li: {
      ...body,
    },
    '.modal-container': {
      display: 'flex',
      background: 'rgba(0, 0, 0, 0.5)',
      opacity: 0,
      transition: 'opacity 250ms ease-in-out',

      '&.modal-container--enter, &.modal-container--enterDone': {
        opacity: 1,
      },

      '&.modal-container--exit, &.modal-container--exitDone': {
        opacity: 0,
      },
    },
    '.svg-inline--fa': {
      width: htmlFontSize,
    },
  },
  app: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    opacity: 0,

    transition: 'opacity 0.5s ease-in-out',
  },
  appLoaded: {
    opacity: 1,
  },
  container: {
    flex: 1,
    marginBottom: spacing.normal,
  },
});
